import { useQuery } from '@tanstack/react-query'

import { graphQlApi } from '../../api/graph-ql.api'

export const productsTypeKey = ['products-type-key']

export function useGetProductType() {
  const query = useQuery({
    queryKey: productsTypeKey,
    queryFn: async () => {
      const response = await graphQlApi.productTypeRequest()
      return response
    },
  })

  return query
}
