import { ForwardedRef, SyntheticEvent, forwardRef, useState } from 'react'

import { Box } from '@mui/material'
import Chrome, { ChromeProps } from '@uiw/react-color-chrome'

import { getStyles } from 'shared/utils'

import { TextField } from '../TextField'

import defaultStyle from './styles'

export type ColorPickerProps = {
  handleChange: (color: string | SyntheticEvent) => void
  value: string
  label?: string
} & Omit<ChromeProps, 'color' | 'onChange'>

export const ColorPicker = forwardRef(function ColorPicker(
  { handleChange, value, label }: ColorPickerProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  const [open, setOpen] = useState<boolean>(false)

  const styles = getStyles(defaultStyle)

  return (
    <Box {...styles('wrapper')}>
      <TextField value={value} onChange={handleChange} label={label} />
      <Box
        onClick={() => setOpen(!open)}
        {...styles('colorSelector', { backgroundColor: value })}
      />
      {open && (
        <Chrome
          ref={ref}
          onChange={(color) => handleChange(color.hex)}
          color={value}
          style={{
            position: 'absolute',
            bottom: '0',
            left: '105%',
          }}
          tabIndex={0}
          onBlur={() => setOpen(false)}
        />
      )}
    </Box>
  )
})
