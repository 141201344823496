import { createTheme } from '@mui/material/styles'

import { ForceAny } from 'shared/typescript'

import { lightThemePalette } from './light.theme'
import { CustomPaletteOptions, StyleOverrideProps } from './types'

// type overrides for the theme
declare module '@mui/material/styles' {
  interface TypographyVariants {
    [key: string]: ForceAny
  }
  interface TypographyVariantsOptions {
    [key: string]: ForceAny
  }
  interface Palette {
    [key: string]: ForceAny
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface PaletteOptions extends CustomPaletteOptions {}

  /* Uncomment the following code to add custom breakpoints */
  // interface BreakpointOverrides {
  //   xs: false; // removes the `xs` breakpoint
  //   sm: false;
  //   md: false;
  //   lg: false;
  //   xl: false;
  //   mobile: true; // adds the `mobile` breakpoint
  //   tablet: true;
  //   laptop: true;
  //   desktop: true;
  // }
}

/**
 * @description Right now, the theme is using the light theme options and the inter font.
 * Create new theme option or add new font as per the requirement.
 */
const theme = createTheme({
  // All the colors- palette should be defined in the theme options file
  palette: lightThemePalette,
  /**
   * @todo Set Font
   */
  typography: {
    fontFamily: 'GeneralSans',
  },

  // Uncomment the following code to add custom breakpoints
  // breakpoints: {
  //   values: {
  //     mobile: 0,
  //     tablet: 601,
  //     laptop: 1024,
  //     desktop: 1200,
  //   },
  // },

  /**
   * Include any mui component override here to be applied globally
   * To create custom mui component @see https://mui.com/material-ui/customization/creating-themed-components/
   */
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: ({ theme }: StyleOverrideProps) => ({
          '& *': {
            '&::-webkit-scrollbar ': {
              width: '12px',
              height: '4px',
            },

            '&::-webkit-scrollbar-track': {
              borderRadius: '100px',
              backgroundColor: theme.palette.custom.track,
              borderWidth: '2px',
              borderStyle: 'solid',
            },

            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.custom.thumb,
              borderRadius: '100px',
              borderWidth: '2px',
              maxHeight: '30px',
            },

            '&::-webkit-scrollbar-thumb:hover': {
              // backgroundColor: theme.palette.custom.main,
              zoom: 1.1,
            },
          },
        }),
      },
    },
    MuiButton: {
      // The props to change the default for.
      // Uncomment -> No more ripple, on the whole application 💣!
      defaultProps: {
        // disableRipple: true,
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
        },
        contained: ({ theme }) => ({
          backgroundColor: theme.palette.primary.main,
          borderRadius: '8px',
          height: '40px',
          color: theme.palette.common.white,
          '&:hover': {
            backgroundColor: theme.palette.primary.main,
          },
        }),
        text: () => ({
          padding: '0 !important',
          display: 'inline',
        }),
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: ({ theme }) => ({
          boxShadow: 'none',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: theme.palette.custom.border.main,
          borderRadius: '8px !important',
          '&::before': {
            display: 'none',
          },
        }),
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: ({ theme: _theme }) => ({
          minHeight: '55px',
        }),
        expandIconWrapper: ({ theme }) => ({
          color: theme.palette.primary.main,
        }),
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: ({ theme }) => ({
          width: '34px',
          height: '18px',
          padding: '0',
          '& .MuiSwitch-switchBase': {
            padding: '0',
            margin: '2px',
            transitionDuration: '300ms',
            '&.Mui-checked': {
              transform: 'translateX(16px)',
              color: theme.palette.common.white,
              '& + .MuiSwitch-track': {
                background: 'linear-gradient(135deg, #43CBFF 0%, #9708CC 100%)',
                opacity: '1',
                border: '0',
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: '0.5',
              },
            },

            '&.Mui-disabled .MuiSwitch-thumb': {
              color: theme.palette.text.secondary,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: theme.palette.mode === 'light' ? '0.7' : '0.3',
            },
          },
          '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: '14px',
            height: '14px',
          },
          '& .MuiSwitch-track': {
            borderRadius: '13px',
            backgroundColor: theme.palette.custom.border.main,
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
              duration: '500ms',
            }),
          },
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ theme }) => ({
          height: '45px',
          boxSizing: 'border-box',
          borderRadius: '8px',
          position: 'relative',
          borderWidth: 1,
          borderStyle: 'solid',
          fontSize: '14px',
          padding: '10px 8px',
          borderColor: theme.palette.custom.border.main,
          width: '100%',
          [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
            height: '30px',
          },
          '&.Mui-error': {
            borderColor: `${theme.palette.error.main} !important`,
            color: theme.palette.error.main,
          },
          '&.Mui-focused': {
            borderColor: theme.palette.primary.main,
          },
        }),
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ theme }) => ({
          '.MuiInputBase-root': {
            border: '0',
            borderRadius: '8px',
          },
          '.MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-notchedOutline':
            {
              borderColor: theme.palette.custom.border.main,
              borderWidth: '1px !important',
            },
        }),
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: () => ({
          width: '100% !important',
        }),
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          transform: 'scale(1)',
          fontSize: '14px',
          marginBottom: '5px',
          fontWeight: '500',
          color: theme.palette.text.primary,
        }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: ({ theme }) => ({
          height: '45px',
          boxSizing: 'border-box',
          borderRadius: '8px',
          position: 'relative',
          borderWidth: 1,
          borderStyle: 'solid',
          fontSize: '14px',
          padding: '10px 8px',
          borderColor: theme.palette.custom.border.main,
          width: '100%',
          outline: 'none',
          [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
            height: '30px',
          },
          '&.Mui-error': {
            borderColor: `${theme.palette.error.main} !important`,
            color: theme.palette.error.main,
          },
          '&.Mui-focused': {
            borderColor: theme.palette.primary.main,
          },
        }),
      },
      // defaultProps: {
      //   margin: "dense",
      // },
    },
  },
})

export default theme
