import { Box } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import Radio from '@mui/material/Radio'
import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup'

import { getStyles } from 'shared/utils'

import defaultStyles from './styles'

type OptionType = {
  value: string
  label: string
}

type RadioButtonGroupType = RadioGroupProps & {
  options: OptionType[]
  formLabel?: string
}

export function RadioButtonGroup({
  options,
  formLabel,
  name,
}: RadioButtonGroupType) {
  const styles = getStyles(defaultStyles)

  return (
    <FormControl size="small">
      {formLabel && (
        <FormLabel id="radio-buttons-group-label">{formLabel}</FormLabel>
      )}
      <RadioGroup
        row
        aria-labelledby="radio-buttons-group-label"
        name={name}
        {...styles('radioGroup')}
      >
        {options.map((option) => {
          return (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio {...styles('radioInput')} />}
              color="primary.main"
              label={
                <Box {...styles('labelWrapper')}>
                  <Box
                    className="gradient-wrapper"
                    {...styles('gradientWrapper')}
                  >
                    <Box {...styles('innerCircle')} />
                  </Box>
                  <Box>{option.label}</Box>
                </Box>
              }
              {...styles('controlLabel')}
            />
          )
        })}
      </RadioGroup>
    </FormControl>
  )
}
