import { StylesClasses, createStyles } from 'shared/utils'

const styles = createStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'custom.border.main',
    width: '100%',
    flexBasis: '100%',
    padding: '10px 18px',
    backgroundColor: 'custom.background.main',
    height: '56px',
  },

  title: {
    fontSize: '20px',
    fontWeight: '500',
    width: 'fit-content',
  },

  childrenWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
  },

  customChildrenWrapper: {
    justifyContent: 'space-between',
  },

  children: {
    marginRight: '10px',
  },

  rightActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  btn: {
    marginRight: '10px',
    borderRadius: '12px',
  },
})

export type StylesClassNames = StylesClasses<typeof styles>

export default styles
