export const defaultEn = {
  themeSettingsLabel: {
    inventory: 'Inventory',
    productCard: 'Product Card',
    help: 'Help',
    share: 'Share',
    productDisplayPage: 'Product Display Page',
    searchPage: 'Search Page',
    menuConfig: 'Menu Config',
  },

  deeplink: {
    title: 'Deeplink',
    actions: {
      cancel: 'Cancel',
      saveChanges: 'Save Changes',
    },

    tabs: {
      product: 'Product',
      collection: 'Collection',
      writeReview: 'Write a Review',
      screen: 'Screen',
    },

    product: {
      form: {
        title: 'Title',
        titlePlaceholder: 'Search with the title',
        productVendor: 'Product Vendor',
        productVendorPlaceholder: 'Search with product vendor',
        taggedWith: 'Tagged With',
        taggedWithPlaceholder: 'Search with the title',
        status: 'Status',
        statusPlaceholder: 'Select Status',
        productType: 'Product Type',
        productTypePlaceholder: 'Search with product type',
        collection: 'Collection',
        collectionPlaceholder: 'Search with collection',
        sku: 'Sku',
        skuPlaceholder: 'Search with sku',
        barcode: 'Barcode',
        barcodePlaceholder: 'Search with barcode',
        sort: 'Sort',
        sortPlaceholder: 'Sort',
      },
      qrTitle: 'QR Code for Deeplink',
      downloadQrCode: 'Download QR Code',
    },

    collection: {
      form: {
        title: 'Title',
        titlePlaceholder: 'Search with the title',
        sort: 'Sort',
        sortPlaceholder: '"Search with the title',
      },
    },
  },

  signIn: {
    title: 'Login',
    subtitle:
      'Embark on your digital journey through our seamless and secure login experience!',
    helperText: 'Forgot Password?',
    labels: {
      email: 'Email Address',
      password: 'Password',
    },
    placeholders: {
      email: 'Enter Email Address',
      password: 'Enter Password',
    },
    validations: {
      email: {
        required: 'Email address is required',
        valid: 'Enter a valid email address',
      },
      passwordRequired: 'Enter password to proceed',
    },
    orText: 'or',
  },

  signUp: {
    title: 'Get Started',
    subtitle: 'Begin your creative journey with our simple sign-up process!',
    helperText: 'Already Have an Account?',
    helperLinkText: 'Login',
    labels: {
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email Address',
      password: 'Password',
      confirmPassword: 'Confirm Password',
      country: 'Country',
    },
    placeholders: {
      firstName: 'Enter First Name',
      lastName: 'Enter Last Name',
      email: 'Enter Email Address',
      password: 'Enter Password',
      confirmPassword: 'Re-enter Password',
      country: 'Select Country',
    },
    validations: {
      firstNameRequired: 'First Name is required',
      lastNameRequired: 'Last Name is required',
      email: {
        required: 'Email address is required',
        valid: 'Enter a valid email address',
      },
      password: {
        required: 'Password is required',
        length: 'Password must be at least 8 characters long',
        lowercase: 'Password must contain at least 1 lowercase letter',
        uppercase: 'Password must contain at least 1 uppercase letter',
        number: 'Password must contain at least 1 number',
        symbol: 'Password must contain at least 1 symbol',
      },
      passwordDoNotMatch: 'Confirm password do not match with password',
      validCountry: 'Enter a valid country name ',
    },
    orText: 'or',
  },

  forgotPassword: {
    title: 'Forgot Password',
    subtitle:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Esse, ab.',
    helperText: 'Remember your password?',
    helperLinkText: 'Login',
    labels: {
      email: 'Email Address',
    },
    placeholders: {
      email: 'Enter Email Address',
    },
    validations: {
      email: {
        required: 'Email address is required',
        valid: 'Enter a valid email address',
      },
      passwordRequired: 'Enter password to proceed',
    },
    buttonText: 'Send Reset Link',
    orText: 'or',
  },

  resetPassword: {
    title: 'Reset Password',
    subtitle:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Esse, ab.',
    helperText: 'Remember your password?',
    helperLinkText: 'Login',
    labels: {
      password: 'New Password',
      confirmPassword: 'Confirm New Password',
    },
    placeholders: {
      password: 'Enter password',
      confirmPassword: 'Confirm New password',
    },
    validations: {
      password: {
        required: 'Password is required',
        length: 'Password must be at least 8 characters long',
        lowercase: 'Password must contain at least 1 lowercase letter',
        uppercase: 'Password must contain at least 1 uppercase letter',
        number: 'Password must contain at least 1 number',
        symbol: 'Password must contain at least 1 symbol',
      },
      passwordMatch: 'Password do not match',
    },
    buttonText: 'Change Password',
  },

  notFound: {
    heading: '404',
    emoji: '🔌',
    subheading: 'Looks like this page is on a coffee break.',
    description:
      'No worries, app builder extraordinaire! We&apos;re sending a team of friendly robots to investigate.  In the meantime, why not take a break and come back refreshed to conquer this app!',
    buttonLabel: 'Back To HomePage',
  },
  plans: {
    title: {
      first: 'Choose the plan that’s best ',
      second: 'for you',
      third: '!',
      fourth: 'And start ',
      fifth: 'Building Today',
      sixth: '🥳',
    },
    durations: {
      monthly: 'Monthly',
      yearly: 'Yearly',
    },
  },
  freeTrialModal: {
    buttonText: 'Select Plans',
    planExpiredCard: {
      title: 'Your Trial Has Ended! 😓',
      subtitle: 'Take Your App to the Next Level! 🤩',
      description:
        " You've built something amazing in your free trial, and we don't want the journey to stop there! Upgrading to Premium unlocks powerful features to help your app truly shine:",
    },
  },
}
