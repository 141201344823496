import { lazy } from 'react'

export const HomePage = lazy(async () => {
  const { HomePage } = await import('./home')
  return { default: HomePage }
})

export const DashboardPage = lazy(async () => {
  const { DashboardPage } = await import('./dashboard')
  return { default: DashboardPage }
})

export const SignInPage = lazy(async () => {
  const { SignInPage } = await import('./sign-in')
  return { default: SignInPage }
})

export const SignUpPage = lazy(async () => {
  const { SignUpPage } = await import('./sign-up')
  return { default: SignUpPage }
})

export const AccountPage = lazy(async () => {
  const { AccountPage } = await import('./account')
  return { default: AccountPage }
})

export const ThemesPage = lazy(async () => {
  const { ThemesPage } = await import('./themes')
  return { default: ThemesPage }
})

export const DeepLinkPage = lazy(async () => {
  const { DeepLinkPage } = await import('./deepLink')
  return { default: DeepLinkPage }
})

export const RedirectionPage = lazy(async () => {
  const { RedirectionPage } = await import('./redirection')
  return { default: RedirectionPage }
})

export const NotFoundPage = lazy(async () => {
  const { NotFoundPage } = await import('./not-found')
  return { default: NotFoundPage }
})

export const ForgotPasswordPage = lazy(async () => {
  const { ForgotPasswordPage } = await import('./forgot-password')
  return { default: ForgotPasswordPage }
})

export const ResetPasswordPage = lazy(async () => {
  const { ResetPasswordPage } = await import('./reset-password')
  return { default: ResetPasswordPage }
})

export const PricingPlanPage = lazy(async () => {
  const { PricingPlanPage } = await import('./plans')
  return { default: PricingPlanPage }
})

export const BrandingPage = lazy(async () => {
  const { BrandingPage } = await import('./branding')
  return { default: BrandingPage }
})
