export interface Plans {
  plan: Plan
  features: Feature[]
}

export interface PlansResponse {
  statusCode: number
  message: string
  data: Plans[]
}

export enum PLAN_DURATIONS {
  monthly = 'MONTHLY',
  yearly = 'YEARLY',
}

interface Feature {
  id: string
  name: string
  description: string
  isActive: boolean
  created_at: string
  updated_at: string
}

interface Plan {
  id: string
  name: string
  planCostId: string
  description: string
  isActive: boolean
  created_at: string
  updated_at: string
  planCost: PlanCost
}

interface PlanCost {
  id: string
  price: string
  currency: string
  duration: string
  created_at: string
  updated_at: string
}

export type PlansDuration = PLAN_DURATIONS.monthly | PLAN_DURATIONS.yearly

export interface CreateSubscriptionResponse {
  statusCode: number
  message: string
  data: {
    userErrors: []
    confirmationUrl: string
    appSubscription: {
      id: string
    }
  }
}

export type CreateSubscriptionPayload = {
  planId: string
}

export interface ActivePlanResponse {
  statusCode: number
  message: string
  data?: {
    shop?: {
      name?: string
    }
    appByHandle?: {
      installation?: {
        activeSubscriptions?: {
          createdAt: string
          currentPeriodEnd: string
          id: string
          name: string
          status: 'ACTIVE' | 'INACTIVE'
          test: boolean
          trialDays: number
        }[]
      }
    }
  }
}
