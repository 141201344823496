import { Box } from '@mui/material'
import { useForm } from 'react-hook-form'

import { AppButton } from 'shared/components'
import { TextFormField } from 'shared/form'
import { emailRegex, getStyles } from 'shared/utils'

import { paths } from 'entities/config'
import { ForgotPasswordPayload } from 'entities/model'

import { useFeaturesTranslation } from 'features/locale'
import { useForgotPasswordMutation } from 'features/state/auth/auth.mutation'

import { ShopifyLogin } from '../ShopifyLogin'

import defaultStyles from './styles'

type FormData = ForgotPasswordPayload

export function ForgotPasswordForm() {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      email: '',
    },
    criteriaMode: 'firstError',
    mode: 'onBlur',
  })

  const { mutate, isPending } = useForgotPasswordMutation()

  const onSubmit = (formData: FormData) => {
    mutate(formData)
  }

  const { tFeatures } = useFeaturesTranslation('forgotPassword')
  const styles = getStyles(defaultStyles)

  return (
    <Box {...styles('container')}>
      <Box width="100%">
        <Box component="div" {...styles('heading')}>
          {tFeatures('title')}
        </Box>
        <Box component="p" {...styles('caption')}>
          {tFeatures('subtitle')}
        </Box>
        <Box
          component="form"
          {...styles('form')}
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextFormField
            control={control}
            name="email"
            errors={errors.email}
            label={tFeatures('labels.email')}
            placeholder={tFeatures('placeholders.email')}
            type="email"
            rules={{
              required: true,
              pattern: {
                value: emailRegex,
                message: 'Enter a valid email address',
              },
            }}
          />
          <AppButton
            loading={isPending}
            label={tFeatures('buttonText')}
            type="submit"
            variant="contained"
          />
        </Box>
        <Box component="small" {...styles('helperText')}>
          {tFeatures('helperText')}{' '}
          <AppButton variant="text" to={paths.signIn}>
            {tFeatures('helperLinkText')}
          </AppButton>
        </Box>
        <Box component="small" {...styles('orText')}>
          {tFeatures('orText')}
        </Box>
        <ShopifyLogin />
      </Box>
    </Box>
  )
}
