import { useState } from 'react'

import { Box } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'

import { SuccessCheck } from 'shared/assets'
import { AppButton, DefaultModal, TextField } from 'shared/components'
import { TextFormField } from 'shared/form'
import { useSearchParam } from 'shared/hooks'
import { getStyles } from 'shared/utils'

import { useFeaturesTranslation } from 'features/locale'

import defaultStyles from './styles'

export function Dashboard() {
  const { control } = useForm({
    defaultValues: {
      test: 'Test Value',
    },
  })

  const { showSuccessModal, planType } = useSearchParam({
    showSuccessModal: 'successModal',
    planType: 'plan',
  })

  const [openModal, setOpenModal] = useState<boolean>(!!showSuccessModal)
  const location = useLocation()
  const navigate = useNavigate()

  const closeModal = () => {
    setOpenModal(false)
    navigate(location.pathname)
  }
  const { tFeatures } = useFeaturesTranslation('modals.paymentSuccess')

  const styles = getStyles(defaultStyles)

  return (
    <Box>
      <h2>Dashboard Feature</h2>
      <Box sx={{ mx: 2, my: 4, display: 'flex', gap: 2 }}>
        <TextField
          label="Search Products"
          placeholder="Search products by name"
          helperText="Error message"
        />
        <TextFormField label="From Text Field" control={control} name="test" />
      </Box>
      <DefaultModal
        show={openModal}
        customStyles={{ wrapper: defaultStyles.maxWidth }}
        handleClose={closeModal}
      >
        <Box {...styles('wrapper')}>
          <Box {...styles('iconWrapper')} className="center">
            <Box
              component="img"
              src={SuccessCheck}
              alt="success-icon"
              width="50px"
            />
          </Box>
          <Box {...styles('title')}>{tFeatures('title')}</Box>
          <Box {...styles('message')}>
            {tFeatures('message')} &nbsp;{planType || ''}
          </Box>
          <Box {...styles('subtitle')}>
            <Box>{tFeatures('subtitle')}</Box>
            <Box>{tFeatures('description')}</Box>
          </Box>
          <AppButton buttonStyle="gradient" onClick={closeModal}>
            {tFeatures('buttonText')}
          </AppButton>
        </Box>
      </DefaultModal>
    </Box>
  )
}
