import { createStyles } from 'shared/utils'

const styles = createStyles({
  brandingWrapper: {
    display: 'flex',
  },
  formButtons: {
    display: 'flex',
    gap: '15px',

    button: {
      height: '33px',
    },
  },
  sideBar: {
    width: {
      sm: '200px',
      lg: '275px',
    },
    backgroundColor: 'common.white',
    boxShadow: ' 0px 4px 34px 0px #0000001A',
    padding: {
      sm: '10px',
      lg: '20px',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '20px',
    },
  },
  rightSection: {
    flex: '1',
  },
  tabSelectionWrapper: {
    display: 'flex',
    borderRadius: '8px',
    padding: {
      sm: '3px',
      lg: '10px',
    },
    backgroundColor: 'common.white',
    width: 'max-content',
    marginInline: 'auto',
    marginBlock: '30px',
    position: 'relative',
  },
  tabButton: {
    fontSize: {
      sm: '14px',
      lg: '16px',
    },
    padding: {
      sm: '4px 15px',
      lg: '3px 10px',
    },
    borderRadius: '8px',
    fontWeight: '500',
    cursor: 'pointer',
  },
  tabActive: {
    fontSize: {
      sm: '14px',
      lg: '16px',
    },
    background: ' linear-gradient(135deg, #43CBFF 0%, #9708CC 100%)',
    color: 'common.white',
    padding: {
      sm: '4px 15px',
      lg: '3px 10px',
    },
    borderRadius: '8px',
    fontWeight: '500',
    cursor: 'pointer',
  },
  tabContent: {
    display: 'flex',
    justifyContent: 'center',
  },
})

export default styles
