import { StylesClasses, createStyles } from 'shared/utils'

const styles = createStyles({
  wrapper: {
    display: 'flex',
    minHeight: 'calc(100vh - 58px)',
    width: '100%',
    gap: '20px',
  },
  themeSelection: {
    boxShadow: '0px 4px 34px 0px #0000001A',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    alignItems: 'center',
    padding: '20px',
    height: 'calc(100vh - 58px)',
    overflow: 'auto',
    maxWidth: '355px',
    flexBasis: '30%',
  },
  themeSettings: {
    flex: '1',
    paddingTop: '20px',
    paddingBottom: '50px',
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    height: 'calc(100vh - 58px)',
    overflow: 'scroll',
    paddingRight: '20px',
  },
  themeDetailsCard: {
    padding: '15px',
    border: '1px solid',
    borderColor: 'custom.border.main',
    display: 'flex',
    borderRadius: '8px',
    overflow: 'hidden',
  },
  featuresSection: {
    fontSize: '14px',
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
  themeTitle: {
    fontWeight: '600',
    fontSize: '18px',
  },
  themeSubtitle: {
    fontSize: '14px',
  },
  featureItem: {
    display: 'flex',
    gap: '5px',
  },
  themeVisualRepContainer: {
    alignSelf: 'flex-end',
    position: 'relative',
    bottom: '-22px',
  },
  themeImage: {
    position: 'absolute',
    top: '-22%',
    left: '50%',
    width: '120px',
    transform: 'translateX(-50%)',
  },
  inputHalf: {
    width: '50%',
  },
  label: {
    fontSize: '1.2rem',
    marginTop: '10px',
  },
  paymentOptionsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  gateWayList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  addTabWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    border: '1px solid',
    borderColor: 'custom.border.main',
    borderRadius: '8px',
    paddingBlock: '20px',
    gap: '20px',
  },

  addTabHeaderSection: {
    marginRight: 'auto',
    width: '100%',
    paddingInline: '20px',
    borderBottom: '1px solid',
    borderColor: 'custom.border.main',
    paddingBottom: '20px',
  },
  addTabHeading: {
    fontWeight: '600',
  },
  addTabSubheading: {
    fontSize: '14px',
    color: 'text.secondary',
  },
  linkButton: {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    border: 'none',
    backgroundColor: 'secondary.main',
    padding: '0',
  },
  linkDescription: {
    textAlign: 'center',
  },
  linkDescriptionTitle: {
    fontSize: '14px',
    fontWeight: '600',
  },
  linkDescriptionSubtitle: {
    fontSize: '12px',
  },
  customOptionMenu: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: '1px solid',
    borderColor: 'custom.border.main',
    paddingBlock: '15px',
  },
  customMenu: {
    fontWeight: '600',
  },
})

export type StylesClassNames = StylesClasses<typeof styles>

export default styles
