import { ReactNode } from 'react'

import { Navigate, useLocation } from 'react-router-dom'

import { ExclusiveProperty } from 'shared/typescript'

import { paths } from 'entities/config'
import { useUserSession } from 'entities/state/user/user.session'

type Props = { children: ReactNode } & Partial<
  ExclusiveProperty<{
    privateRoute: boolean
    authRoute: boolean
  }>
>

export function RouteGuard({ privateRoute, authRoute, children }: Props) {
  /**
   * Retrieve the isLoggedIn state from local storage
   * Replace this as per session management
   */
  const { data } = useUserSession()
  const isLoggedIn = !!data?.id
  const path = useLocation()
  const { state } = path

  if (privateRoute && !isLoggedIn) {
    return <Navigate to={paths.signIn} state={{ redirectUrl: path }} replace />
  }

  if (authRoute && isLoggedIn) {
    return <Navigate to={state?.redirectUrl ?? paths.dashboard} replace />
  }

  return children
}
