import { Box, Button } from '@mui/material'

import { CustomStyles, getStyles } from 'shared/utils'

import { ModalWrapperProps } from '../m.types'

import defaultStyles, { StylesClassNames } from './styles'

export type SkipProps = {
  title: string
  icon: string
  desc?: string
  customStyles?: CustomStyles<StylesClassNames>
  onClickAction?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export function SkipModal({
  children,
  icon,
  title,
  desc,
  customStyles,
  onClickAction = () => {},
}: SkipProps & ModalWrapperProps) {
  const styles = getStyles(defaultStyles, customStyles)
  return (
    <Box {...styles('wrapper')}>
      <Box {...styles('header')}>
        <Button
          {...styles('skip')}
          disableRipple
          disableTouchRipple
          disableFocusRipple
          onClick={onClickAction}
        >
          Skip
        </Button>
      </Box>
      <Box {...styles('content')}>
        <Box {...styles('imgWrapper')}>
          <Box component="img" src={icon} alt={icon} />
        </Box>
        <Box {...styles('desc')}>{desc}</Box>
        <Box {...styles('title')}>{title}</Box>
        <Box {...styles('children')}>{children}</Box>
      </Box>
    </Box>
  )
}
