import { StylesClasses, createStyles } from 'shared/utils'

const styles = createStyles({
  wrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minHeight: '40%',
    minWidth: '60%',
    backgroundColor: 'common.white',
    padding: '24px 36px',
    borderRadius: '5px',
  },
  imgWrapper: {
    width: '80px',
    height: '80px',
  },
  header: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  skip: {
    fontWeight: '500',
    fontSize: '14px',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },
  desc: {
    fontSize: '24px',
    fontWeight: '500',
    maxWidth: '300px',
  },
  title: {
    fontSize: '24px',
    fontWeight: '700',
    maxWidth: '300px',
    marginBottom: '36px',
  },
  children: {
    maxHeight: '450px',
    overflowY: 'auto',
    width: '100%',
  },
})

export type StylesClassNames = StylesClasses<typeof styles>

export default styles
