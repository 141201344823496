import { forwardRef } from 'react'

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  SvgIconTypeMap,
  TextField,
  TextFieldProps,
} from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'

import { CustomStyles, getStyles } from 'shared/utils'

import defaultStyles, { StyleClassNames } from './styles'

export type SelectOptions = {
  label: string
  value: string | number
  isActive?: boolean
}[]

export type SelectFieldProps = TextFieldProps & {
  label?: string
  helperText?: string
  readOnly?: boolean
  options: SelectOptions
  customStyles?: CustomStyles<StyleClassNames>
}

type MuiIconType = OverridableComponent<SvgIconTypeMap<object, 'svg'>> & {
  muiName: string
}

export type DropDownIconProps = {
  readonly: boolean
} & MuiIconType

export const SelectField = forwardRef(function SelectField(
  {
    label,
    fullWidth = true,
    readOnly = false,
    customStyles,
    error = false,
    options,
    helperText,
    ...restSelectFieldProps
  }: SelectFieldProps,
  ref: React.ForwardedRef<HTMLSelectElement>
) {
  const styles = getStyles(defaultStyles, customStyles)

  return (
    <FormControl
      fullWidth={fullWidth}
      variant="standard"
      {...styles('wrapper', {
        color: readOnly ? 'text.secondary' : 'initial',
      })}
    >
      {label && (
        <InputLabel
          shrink
          {...styles('label', {
            color: readOnly ? 'text.secondary' : 'initial',
          })}
          htmlFor={label}
        >
          {label}
        </InputLabel>
      )}
      <TextField
        select
        error={error}
        inputRef={ref}
        {...restSelectFieldProps}
        {...styles('input', {
          color: readOnly ? 'text.secondary' : 'initial',
        })}
      >
        {options.map(({ label, value, isActive = true }) => (
          <MenuItem key={value} value={value} disabled={!isActive}>
            {label}
          </MenuItem>
        ))}
      </TextField>
      {!!helperText && (
        <FormHelperText
          {...styles('helperText', {
            color: error ? 'error.main' : 'text.secondary',
          })}
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  )
})
