import { Box } from '@mui/material'

import { CheckBox, TextField } from 'shared/components'
import { getStyles } from 'shared/utils'

import { useEntitiesTranslation } from 'entities/locale'

import defaultStyles from './styles'

type PaymentOptionsProps = {
  paymentIcon: string
}

export function PaymentOptions({ paymentIcon }: PaymentOptionsProps) {
  const { tEntities } = useEntitiesTranslation('paymentFields')

  const styles = getStyles(defaultStyles)

  return (
    <Box {...styles('wrapper')}>
      <CheckBox customStyles={{ controlLabel: defaultStyles.checkbox }} />
      <Box {...styles('paymentsIcon')}>
        <Box component="img" src={paymentIcon} />
      </Box>
      <TextField
        label={tEntities('installment.label')}
        placeholder={tEntities('installment.placeholder')}
        customStyles={{
          label: defaultStyles.label,
        }}
      />
      <TextField
        label={tEntities('minAmount.label')}
        placeholder={tEntities('minAmount.placeholder')}
        customStyles={{
          label: defaultStyles.label,
        }}
        defaultValue={100}
      />
      <TextField
        label={tEntities('payLater.label')}
        placeholder={tEntities('payLater.placeholder')}
        customStyles={{
          label: defaultStyles.label,
        }}
      />
    </Box>
  )
}
