import {
  Controller,
  ControllerProps,
  ControllerRenderProps,
  FieldValues,
} from 'react-hook-form'

import { ColorPicker, ColorPickerProps } from 'shared/components'

type Props<TFormValues extends FieldValues> = Omit<
  ColorPickerProps,
  keyof ControllerRenderProps | 'handleChange'
> &
  Omit<ControllerProps<TFormValues>, 'render'>

export function ColorPickerFormField<TFormValues extends FieldValues>({
  name,
  control,
  rules,
  shouldUnregister,
  ...restColorPickerProps
}: Props<TFormValues>) {
  return (
    <Controller
      render={({ field }) => {
        const { onChange: handleChange, value, ...restField } = field
        return (
          <ColorPicker
            handleChange={handleChange}
            value={value}
            {...restField}
            {...restColorPickerProps}
          />
        )
      }}
      control={control}
      name={name}
      rules={rules}
      shouldUnregister={shouldUnregister}
    />
  )
}
