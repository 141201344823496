import { useEffect, useMemo, useState } from 'react'

import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { Box } from '@mui/material'
import { useForm } from 'react-hook-form'
import QRCode from 'react-qr-code'

import { AppButton, Loader, Modal, ProductCard } from 'shared/components'
import { TextFormField } from 'shared/form'
import { SelectFormField } from 'shared/form/form-fields/SelectFormField'
import { CustomStyles, getStyles } from 'shared/utils'

import {
  defaultProductQueryVariables,
  ProductQueryVariables,
} from 'entities/queries'
import {
  useGetCollections,
  useGetProducts,
  useGetProductTags,
  useGetProductType,
} from 'entities/state'
import {
  selectFieldOptionEdgeTransformer,
  selectFieldOptionTransformer,
} from 'entities/utils/common.utils'

import { useFeaturesTranslation } from 'features/locale'

import { sortOptions, statusOptions } from '../../../constants'

import defaultStyles, { StylesClassNames } from './styles'

type ProductProps = {
  customStyles?: CustomStyles<StylesClassNames>
}

type ProductForm = {
  title: string
  productVendor: string
  productTags: string
  status: string
  productType: string
  collection: string
  sku: string
  barcode: string
  sort: string
}

export function Products({ customStyles }: ProductProps) {
  const { tFeatures } = useFeaturesTranslation('deeplink.product')
  const styles = getStyles(defaultStyles, customStyles)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [productVariables, setProductVariables] =
    useState<ProductQueryVariables>(defaultProductQueryVariables)

  const { data: products, isLoading: isLoadingProducts } =
    useGetProducts(productVariables)
  const { data: productsTypesData } = useGetProductType()
  const { data: productsTagsData } = useGetProductTags()
  const { data: collectionsData } = useGetCollections()

  const productTypeOptions = useMemo(
    () =>
      selectFieldOptionEdgeTransformer(productsTypesData?.productTypes.edges),
    [productsTypesData]
  )

  const productsTagsOptions = useMemo(
    () => selectFieldOptionEdgeTransformer(productsTagsData?.productTags.edges),
    [productsTagsData]
  )

  // selectFieldOptionTransformer
  const collectionsOptions = useMemo(
    () => selectFieldOptionTransformer(collectionsData?.collections.nodes),
    [collectionsData]
  )

  const { control, handleSubmit, watch } = useForm<ProductForm>({
    mode: 'onTouched',
    defaultValues: {
      title: '',
      productVendor: '',
      productTags: '',
      status: '',
      productType: '',
      collection: '',
      sku: '',
      barcode: '',
      sort: '',
    },
  })

  const handleFormSubmit = (formData: ProductForm) => {
    setProductVariables({
      ...defaultProductQueryVariables,
      productTags: `${formData.productTags}*`,
      productType: `${formData.productType}*`,
      title: `${formData.title}*`,
    })
  }

  useEffect(() => {
    const subscription = watch(() => {
      void handleSubmit(handleFormSubmit)()
    })
    return () => subscription.unsubscribe()
  }, [handleSubmit, watch])

  return (
    <Box {...styles('wrapper')}>
      <Box component="form" {...styles('leftWrapper')}>
        <Box {...styles('fieldWrapper')}>
          <TextFormField
            name="title"
            label={tFeatures('form.title')}
            control={control}
            placeholder={tFeatures('form.titlePlaceholder')}
            type="search"
            startAdornment={
              <Box
                component="img"
                src="/assets/icons/search.svg"
                alt="search-icon"
                {...styles('searchIcon')}
              />
            }
          />
        </Box>
        <Box {...styles('fieldWrapper')}>
          <TextFormField
            name="productVendor"
            label={tFeatures('form.productVendor')}
            control={control}
            placeholder={tFeatures('form.productVendorPlaceholder')}
          />
        </Box>
        <Box {...styles('fieldWrapper')}>
          <SelectFormField
            name="productTags"
            label={tFeatures('form.taggedWith')}
            control={control}
            placeholder={tFeatures('form.taggedWithPlaceholder')}
            options={productsTagsOptions}
          />
        </Box>
        <Box {...styles('fieldWrapper')}>
          <SelectFormField
            name="status"
            label={tFeatures('form.status')}
            control={control}
            placeholder={tFeatures('form.statusPlaceholder')}
            options={statusOptions}
          />
        </Box>
        <Box {...styles('fieldWrapper')}>
          <SelectFormField
            name="productType"
            label={tFeatures('form.productType')}
            control={control}
            placeholder={tFeatures('form.productTypePlaceholder')}
            options={productTypeOptions}
          />
        </Box>
        <Box {...styles('fieldWrapper')}>
          <SelectFormField
            name="collection"
            label={tFeatures('form.collection')}
            control={control}
            placeholder={tFeatures('form.collectionPlaceholder')}
            options={collectionsOptions}
          />
        </Box>
        <Box {...styles('fieldWrapper')}>
          <TextFormField
            name="sku"
            label={tFeatures('form.sku')}
            control={control}
            placeholder={tFeatures('form.skuPlaceholder')}
          />
        </Box>
        <Box {...styles('fieldWrapper')}>
          <TextFormField
            name="barcode"
            label={tFeatures('form.barcode')}
            control={control}
            placeholder={tFeatures('form.barcodePlaceholder')}
          />
        </Box>
        <Box {...styles('fieldWrapper')}>
          <SelectFormField
            name="sort"
            label={tFeatures('form.sort')}
            control={control}
            placeholder={tFeatures('form.sortPlaceholder')}
            options={sortOptions}
          />
        </Box>
      </Box>
      <Box {...styles('rightWrapper')}>
        <Loader
          loading={isLoadingProducts}
          customStyles={{ wrapper: defaultStyles.products }}
        >
          {products?.products.nodes.map((item) => (
            <ProductCard
              key={item.title}
              onClick={() => setOpenModal(true)}
              title={item.title}
              image={item.featuredImage?.transformedSrc ?? ''}
              customStyles={{
                wrapper: {
                  flexBasis: '30%',
                  margin: '8px 10px',
                },
              }}
            />
          ))}
        </Loader>
      </Box>
      <Modal
        open={openModal}
        openAs="DefaultModal"
        title="Deep-link"
        handleClose={() => setOpenModal(false)}
      >
        <Box {...styles('modal')}>
          <Box {...styles('modalHeading')}>{tFeatures('qrTitle')}</Box>
          <Box {...styles('deeplinkQr')}>
            <QRCode
              value="https://en.wikipedia.org/wiki/Common_sunflower"
              size={130}
              viewBox="0 0 130 130"
            />
          </Box>
          <Box {...styles('deeplinkCopy')}>
            <Box {...styles('deeplink')}>
              https://en.wikipedia.org/wiki/Common_sunflower
            </Box>
            <ContentCopyIcon {...styles('copyIcon')} />
          </Box>
          <AppButton
            label={tFeatures('downloadQrCode')}
            startIcon={<FileDownloadOutlinedIcon />}
            variant="outlined"
            {...styles('downloadBtn')}
          />
        </Box>
      </Modal>
    </Box>
  )
}
