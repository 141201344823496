import { Box } from '@mui/material'

import { TickIcon } from 'shared/assets'
import { AppButton } from 'shared/components'
import { getStyles } from 'shared/utils'

import { useEntitiesTranslation } from 'entities/locale'
import { Plans } from 'entities/model'

import { useCreateSubscriptionMutation } from 'features/state'

import defaultStyles from './styles'

type PricingProps = {
  isPlatinum?: boolean
  data: Plans
}

const durationsText: Record<string, string> = {
  MONTHLY: 'month',
  YEARLY: 'year',
}

export function PricingCard({ isPlatinum = false, data }: PricingProps) {
  const { tEntities } = useEntitiesTranslation('pricingCard')
  const { mutate, isPending } = useCreateSubscriptionMutation()

  const selectPlan = (planId: string) => {
    mutate({ planId })
  }

  const styles = getStyles(defaultStyles)

  const { features, plan } = data
  const { planCost } = plan

  return (
    <Box {...styles(isPlatinum ? 'platinum' : 'wrapper')}>
      <Box {...styles('title')}>{plan.name}</Box>
      <Box
        {...(styles('description'),
        { color: isPlatinum ? 'custom.border.light' : '' })}
      >
        {plan.description}
      </Box>

      <Box {...styles('priceSection')}>
        <Box>
          <Box component="span" {...styles('currentPrice')}>
            {`${planCost.currency} ${planCost.price}`}
          </Box>
          <Box
            component="span"
            {...(styles('durationIndicator'),
            { color: isPlatinum ? 'custom.border.light' : '' })}
          >
            {`/${durationsText[planCost.duration]}`}
          </Box>
        </Box>
        {/* <Box>
          <Box
            component="span"
            {...styles('comparedPrice'),{ color: isPlatinum ? 'custom.border.light' : '' }}
          >
            $99
          </Box>
          <Box
            component="span"
            {...styles('durationIndicator'),{ color: isPlatinum ? 'custom.border.light' : '' }}
          >
            /month
          </Box>
        </Box> */}
      </Box>
      <Box {...styles('featuresSection')}>
        {features.map((feature) => {
          return (
            <Box {...styles('featuresItem')} key={feature.id}>
              <Box component="img" alt="tick" src={TickIcon} width={16} />
              <Box>{feature.name}</Box>
            </Box>
          )
        })}
      </Box>

      <AppButton
        loading={isPending}
        onClick={() => selectPlan(plan.id)}
        variant="outlined"
        customStyles={{ wrapper: defaultStyles.button }}
      >
        {tEntities('buttonText')}
      </AppButton>
      {isPlatinum && (
        <Box {...styles('mostPopular')}>{tEntities('mostPopular')}</Box>
      )}
    </Box>
  )
}
