import { useState } from 'react'

import { Box, Tab as MuiTab, Tabs as MuiTabs } from '@mui/material'

import { CustomStyles, getStyles } from 'shared/utils'

import defaultStyles, { StylesClassNames } from './styles'

export type TabValue = number | string

type TabsProps = {
  customStyles?: CustomStyles<StylesClassNames>
  tabsData: {
    label: string
    value: TabValue
    icon?: string | React.ReactElement
  }[]
  iconPosition?: 'bottom' | 'top' | 'end' | 'start'
  selectedTab: (value: TabValue) => void
}

export function Tabs({
  customStyles,
  tabsData,
  iconPosition = 'end',
  selectedTab,
}: TabsProps) {
  const styles = getStyles(defaultStyles, customStyles)

  const [value, setValue] = useState<TabValue>(tabsData[0]?.value ?? 0)
  const handleChange = (event: React.SyntheticEvent, newValue: TabValue) => {
    setValue(newValue)
    selectedTab(newValue)
  }

  return (
    <Box {...styles('wrapper')}>
      <MuiTabs
        value={value}
        onChange={handleChange}
        {...styles('tabsWrapper')}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
      >
        {tabsData.map((data) => (
          <MuiTab
            label={data.label}
            value={data.value}
            icon={data.icon}
            iconPosition={iconPosition}
            onClick={() => setValue(data.value)}
            {...styles('tab')}
          />
        ))}
      </MuiTabs>
    </Box>
  )
}

// type TabPanelProps<TValue extends string | number> = {
//   value: TValue
//   seletected: TValue
// }
// export function TabPanel<
//   TValue extends string | number,
// >({}: TabPanelProps<TValue>) {}
