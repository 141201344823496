import { useState } from 'react'

import { Tabs, TabValue } from 'shared/components'

import { DashboardLayoutWrapper } from 'entities/components'

import { useFeaturesTranslation } from 'features/locale'

import { Collections } from './Collections'
import { Products } from './Products'
import { Screens } from './Screens'
import { WriteReview } from './WriteReview'

export enum DEEPLINK_TAB {
  PRODUCT = 'product',
  COLLECTION = 'collection',
  WRITE_REVIEW = 'writeReview',
  SCREEN = 'screen',
}

export function DeepLink() {
  const { tFeatures } = useFeaturesTranslation('deeplink')
  const [currentTab, setCurrentTab] = useState<TabValue>(DEEPLINK_TAB.PRODUCT)

  return (
    <DashboardLayoutWrapper
      title={tFeatures('title')}
      headerChildren={
        <Tabs
          tabsData={[
            {
              label: tFeatures('tabs.product'),
              value: DEEPLINK_TAB.PRODUCT,
            },
            {
              label: tFeatures('tabs.collection'),
              value: DEEPLINK_TAB.COLLECTION,
            },
            {
              label: tFeatures('tabs.writeReview'),
              value: DEEPLINK_TAB.WRITE_REVIEW,
            },
            { label: tFeatures('tabs.screen'), value: DEEPLINK_TAB.SCREEN },
          ]}
          selectedTab={(val) => setCurrentTab(val)}
        />
      }
      customStyles={{
        childrenWrapper: {
          justifyContent: 'space-between',
        },
      }}
    >
      {currentTab === DEEPLINK_TAB.PRODUCT && <Products />}

      {currentTab === DEEPLINK_TAB.COLLECTION && <Collections />}

      {currentTab === DEEPLINK_TAB.WRITE_REVIEW && <WriteReview />}

      {currentTab === DEEPLINK_TAB.SCREEN && <Screens />}
    </DashboardLayoutWrapper>
  )
}
