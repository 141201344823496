import { createStyles, StylesClasses } from 'shared/utils'

const styles = createStyles({
  wrapper: {
    width: '100%',
    outline: 'none',
    borderRadius: '8px',

    '& .MuiInputBase-root': {
      borderRadius: '8px',
    },
    position: 'relative',
    '& .label': {
      display: 'inline',
      fontSize: '15px',
      marginBottom: '10px',
    },
  },

  label: {
    position: 'static',
    fontWeight: '500',
  },

  adornment: {
    position: 'relative',
    top: '-10px',
  },

  input: {
    width: '100%',
  },

  helperText: {
    whiteSpace: 'wrap',
    position: 'absolute',
    bottom: '0',
    transform: 'translateY(105%)',
  },
})

export type StyleClassNames = StylesClasses<typeof styles>

export default styles
