import { Box } from '@mui/material'

import { CustomStyles, getStyles } from 'shared/utils'

import defaultStyles, { StylesClassNames } from './styles'

type ScreensProps = {
  customStyles?: CustomStyles<StylesClassNames>
}

export function Screens({ customStyles }: ScreensProps) {
  const styles = getStyles(defaultStyles, customStyles)
  return (
    <Box {...styles('wrapper')}>
      <Box {...styles('leftWrapper')} />
      <Box {...styles('rightWrapper')} />
    </Box>
  )
}
