import { useQuery } from '@tanstack/react-query'

import { graphQlApi } from '../../api/graph-ql.api'

export const collectionsKey = ['collections-key']

export function useGetCollections() {
  const query = useQuery({
    queryKey: collectionsKey,
    queryFn: async () => {
      const response = await graphQlApi.collectionsRequest()
      return response
    },
  })

  return query
}
