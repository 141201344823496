import {
  Controller,
  ControllerProps,
  ControllerRenderProps,
  FieldError,
  FieldValues,
} from 'react-hook-form'

import { SelectField, SelectFieldProps } from 'shared/components'

type Props<TFormValues extends FieldValues> = { errors?: FieldError } & Omit<
  SelectFieldProps,
  keyof ControllerRenderProps
> &
  Omit<ControllerProps<TFormValues>, 'render'>

export function SelectFormField<TFormValues extends FieldValues>({
  name,
  control,
  defaultValue,
  rules,
  shouldUnregister,
  ...restTextFieldProps
}: Props<TFormValues>) {
  return (
    <Controller
      render={({ field }) => {
        return <SelectField {...restTextFieldProps} {...field} />
      }}
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      shouldUnregister={shouldUnregister}
    />
  )
}
