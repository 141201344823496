import { SelectOptions } from 'shared/components'

export const sortOptions: SelectOptions = [
  { label: 'Product title A–Z', value: 'Product title A–Z' }, // reverse: false
  { label: 'Product title Z–A', value: 'Product title Z–A' }, // reverse: true
  { label: 'Created (oldest first)', value: 'Created (oldest first)' }, // CREATED_AT // reverse: true
  { label: 'Created (newest first)', value: 'Created (newest first)' }, // CREATED_AT // reverse: false
  { label: 'Updated (oldest first)', value: 'Updated (oldest first)' }, // UPDATED_AT // reverse: true
  { label: 'Updated (newest first)', value: 'Updated (newest first)' }, // UPDATED_AT // reverse: false
  { label: 'Low inventory', value: 'Low inventory' }, // INVENTORY_TOTAL  // reverse: true
  { label: 'High inventory', value: 'High inventory' }, // INVENTORY_TOTAL  // reverse: false
  { label: 'Product type A–Z', value: 'Product type A–Z' }, // PRODUCT_TYPE // reverse: true
  { label: 'Product type Z–A', value: 'Product type Z–A' }, // PRODUCT_TYPE // reverse: false
  { label: 'Vendor A–Z', value: 'Vendor A–Z' }, // VENDOR  // reverse: true
  { label: 'Vendor Z–A', value: 'Vendor Z–A' }, // VENDOR  // reverse: false
]

export const statusOptions: SelectOptions = [
  { label: 'All', value: '' },
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Archived', value: 'ARCHIVED' },
  { label: 'Draft', value: 'DRAFT' },
]

export const sortCollectionOptions: SelectOptions = [
  { label: 'Collection title A–Z', value: 'Collection title A–Z' }, // reverse false
  { label: 'Collection title Z–A', value: 'Collection title Z–A' }, // reverse true
  { label: 'Updated (oldest first)', value: 'Updated (oldest first)' }, // UPDATED_AT // reverse true
  { label: 'Updated (newest first)', value: 'Updated (newest first)' }, // UPDATED_AT // reverse false
]
