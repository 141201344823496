import { ForwardedRef, SyntheticEvent, forwardRef } from 'react'

import { Close } from '@mui/icons-material'
import { Box, InputLabel, TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { FieldError } from 'react-hook-form'

import { CountryType, countries } from 'shared/utils'

export type CountrySelectProps = {
  placeholder?: string
  label?: string
  onChange?: (e: SyntheticEvent) => void
  value?: CountryType
  onBlur?: (e: SyntheticEvent) => void
  ref?: ForwardedRef<HTMLInputElement>
  errors?: FieldError
}

export const CountrySelect = forwardRef(function CountrySelect({
  placeholder,
  label,
  onChange,
  value,
  onBlur,
  errors,
  ref,
}: CountrySelectProps) {
  return (
    <Autocomplete
      id="country-select-demo"
      options={countries}
      value={value}
      clearIcon={value ? <Close /> : null}
      autoHighlight
      onBlur={onBlur}
      onSelect={onChange}
      ref={ref}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img
            loading="lazy"
            width="20"
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            alt=""
          />
          {option.label}
        </Box>
      )}
      renderInput={(params) => (
        <>
          {label && <InputLabel>{label}</InputLabel>}
          <TextField
            error={!!errors}
            helperText={errors?.message}
            {...params}
            size="small"
            placeholder={placeholder || 'Country'}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
            }}
          />
        </>
      )}
    />
  )
})
