import { StylesClasses, createStyles } from 'shared/utils'

const styles = createStyles({
  container: {
    backgroundColor: 'common.white',
    borderRadius: '16px',
    padding: '20px 25px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '20px',
  },
  themeDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  themeName: {
    maxWidth: '50%',
    fontWeight: '500',
  },
  activeThemeBtn: {
    border: '1px solid',
    borderColor: 'success.main',
    padding: '5px 10px',
    borderRadius: '4px',
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    gap: '5px',
    color: 'success.main',
  },
  circle: {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    backgroundColor: 'success.main',
  },
})

export type StylesClassNames = StylesClasses<typeof styles>

export default styles
