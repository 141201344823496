import { StylesClasses, createStyles } from 'shared/utils'

const styles = createStyles({
  wrapper: {
    position: 'relative',
    display: 'flex',
    gap: '10px',
    alignItems: 'flex-end',
  },
  colorSelector: {
    height: '45px',
    aspectRatio: '1/1',
    borderRadius: '8px',
    border: '1px solid',
    borderColor: 'custom.border.main',
  },
})

export type StylesClassNames = StylesClasses<typeof styles>

export default styles
