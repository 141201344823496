export const settingsEn = {
  inventory: {
    storePickup: {
      title: 'Allow In-Store Pickups',
      desc: 'You can set up the option for customers to pick up their online orders at your retail store, curbside, or any location that you choose.',
    },
    preventPurchaseWhenOutOfStock: {
      title: 'Prevent customers from placing order when products out of stock',
      desc: 'You can set up the option for customers to pick up their online orders at your retail store, curbside, or any location that you choose.',
    },
    limitAppUsage: {
      title: 'Limit app usage to countries where you ship',
      desc: 'You can set up the option for customers to pick up their online orders at your retail store, curbside, or any location that you choose.',
    },
  },
  productCard: {
    multipleImage: {
      title: 'Display Multiple Images',
      desc: 'You can set up the option for customers to pick up their online orders at your retail store, curbside, or any location that you choose.',
    },
    variantCount: {
      title: 'Show Variant Count',
      desc: 'You can set up the option for customers to pick up their online orders at your retail store, curbside, or any location that you choose.',
    },
    displayOffers: {
      title: 'Display offers (e.g., 10% off)',
      desc: 'You can set up the option for customers to pick up their online orders at your retail store, curbside, or any location that you choose.',
    },
    comparePrices: {
      title: 'Show compare price',
      desc: 'You can set up the option for customers to pick up their online orders at your retail store, curbside, or any location that you choose.',
    },
    addToBasketBtn: {
      title: 'Include Add to Basket" button',
      desc: 'You can set up the option for customers to pick up their online orders at your retail store, curbside, or any location that you choose.',
    },
    adjustImage: {
      title: 'Adjust image size',
      desc: 'You can set up the option for customers to pick up their online orders at your retail store, curbside, or any location that you choose.',
    },
    fillMode: {
      title: 'Choose Image fill mode',
      desc: 'You can set up the option for customers to pick up their online orders at your retail store, curbside, or any location that you choose.',
    },
  },
  help: {
    supportEmail: {
      title: 'Support Email',
      desc: 'You can set up the option for customers to pick up their online orders at your retail store, curbside, or any location that you choose.',
    },

    faqURL: {
      title: 'URL for FAQ Page, if applicable',
      desc: 'You can set up the option for customers to pick up their online orders at your retail store, curbside, or any location that you choose.',
    },
  },
  share: {
    customShareURL: {
      title: 'Custom Share URL (optional)',
      desc: 'You can set up the option for customers to pick up their online orders at your retail store, curbside, or any location that you choose.',
    },
    shareText: {
      title: 'Share Text (optional)',
      desc: 'You can set up the option for customers to pick up their online orders at your retail store, curbside, or any location that you choose.',
    },
  },
  productDisplay: {
    priceWithTax: {
      title: 'Display prices including taxes for all products',
      desc: 'You can set up the option for customers to pick up their online orders at your retail store, curbside, or any location that you choose.',
    },
    adjustImageSize: {
      title: 'Adjust Image size',
      desc: 'You can set up the option for customers to pick up their online orders at your retail store, curbside, or any location that you choose.',
    },
    imageFillMode: {
      title: 'Choose image fill mode',
      desc: 'You can set up the option for customers to pick up their online orders at your retail store, curbside, or any location that you choose.',
    },
    payLaterOptions: {
      title: 'Manage pay later options',
      desc: 'You can set up the option for customers to pick up their online orders at your retail store, curbside, or any location that you choose.',
      options: {
        paypal: 'paypal',
        afterPay: 'afterPay',
        sezzle: 'sezzle',
        shopPay: 'shopPay',
        klarna: 'klarna',
      },
    },
    universalTabs: {
      title: 'Manage universal tabs such as return policy or payment policy',
      desc: 'You can set up the option for customers to pick up their online orders at your retail store, curbside, or any location that you choose.',
      showTabOnProductPages: {
        heading: 'Show Tabs on Product Pages',
        subheading: 'Lorem ipsum dolor sit consectetur',
        addTabs: 'Add Tabs on Product Pages',
        addTabsDesc:
          'In addition to the product description, include any useful information such as shipping and return policies.',
        buttonLabel: 'Add Tab',
      },
    },
  },
  searchPage: {
    displayRecentlyViewedProducts: {
      title: 'Display recently viewed products on search landing page',
      desc: 'You can set up the option for customers to pick up their online orders at your retail store, curbside, or any location that you choose.',
    },
  },
  menuConfiguration: {
    showWishlist: 'Show Wishlist',
    showRegion: 'Show Region',
    showHelp: 'Show Help',
    showLocateUs: 'Show Locate us',
    showAboutUs: 'Show About Us',
    showShare: 'Show Share',
    customMenu: {
      title: 'Custom Menu Option',
      buttonLabel: 'Add or Edit Menu',
    },
  },
}
