import { Box } from '@mui/material'
import { useForm } from 'react-hook-form'

import { AppButton } from 'shared/components'
import { CountryFormSelect, TextFormField } from 'shared/form'
import {
  getCodeFromCountryName,
  getCountryFromCode,
  getStyles,
  isValidCountry,
} from 'shared/utils'
import {
  emailRegex,
  lowerCaseRegex,
  numberRegex,
  symbolRegex,
  upperCaseRegex,
} from 'shared/utils/model/regex.utils'

import { UpdateUserPayload } from 'entities/model'
import { useUserSession } from 'entities/state/user/user.session'

import { useFeaturesTranslation } from 'features/locale'
import { useUpdateUserMutation } from 'features/state/user/user.mutation'

import defaultStyles from './styles'

type FormData = UpdateUserPayload & {
  confirmPassword?: string
}

export function SignUpForm() {
  const { mutate, isPending } = useUpdateUserMutation()
  const { tFeatures } = useFeaturesTranslation('signUp')
  const { data } = useUserSession()
  const countryName = getCountryFromCode(data?.country)
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      firstName: data?.firstName || '',
      lastName: '',
      email: data?.email || '',
      password: '',
      confirmPassword: '',
      country: countryName || '',
    },
    criteriaMode: 'firstError',
    mode: 'onBlur',
  })

  const onSubmit = (formData: FormData) => {
    const payload = { ...formData }
    delete payload.confirmPassword
    payload.country = getCodeFromCountryName(formData.country) || ''
    mutate(payload)
  }

  const styles = getStyles(defaultStyles)

  return (
    <Box {...styles('container')}>
      <Box width="100%">
        <Box {...styles('heading')}>{tFeatures('title')}</Box>
        <Box component="p" {...styles('caption')}>
          {tFeatures('subtitle')}
        </Box>
        <Box
          component="form"
          {...styles('form')}
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextFormField
            name="firstName"
            control={control}
            errors={errors.firstName}
            label={tFeatures('labels.firstName')}
            placeholder={tFeatures('placeholders.firstName')}
            rules={{
              validate: {
                minLength: (value = '') =>
                  value.trim().length > 0 ||
                  tFeatures('validations.firstNameRequired'),
              },
            }}
          />
          <TextFormField
            name="lastName"
            control={control}
            errors={errors.lastName}
            label={tFeatures('labels.lastName')}
            placeholder={tFeatures('placeholders.lastName')}
            rules={{
              validate: {
                minLength: (value = '') =>
                  value.trim().length > 0 ||
                  tFeatures('validations.lastNameRequired'),
              },
            }}
          />
          <TextFormField
            name="email"
            control={control}
            errors={errors.email}
            label={tFeatures('labels.email')}
            placeholder={tFeatures('placeholders.email')}
            type="email"
            rules={{
              required: {
                value: true,
                message: tFeatures('validations.email.required'),
              },
              pattern: {
                value: emailRegex,
                message: tFeatures('validations.email.valid'),
              },
            }}
          />
          <TextFormField
            name="password"
            control={control}
            errors={errors.password}
            label={tFeatures('labels.password')}
            type="password"
            placeholder={tFeatures('placeholders.password')}
            rules={{
              required: tFeatures('validations.password.required'),
              minLength: {
                value: 8,
                message: tFeatures('validations.password.length'),
              },
              validate: {
                lowercase: (value = '') =>
                  lowerCaseRegex.test(value) ||
                  tFeatures('validations.password.lowercase'),
                uppercase: (value = '') =>
                  upperCaseRegex.test(value) ||
                  tFeatures('validations.password.uppercase'),
                number: (value = '') =>
                  numberRegex.test(value) ||
                  tFeatures('validations.password.number'),
                symbol: (value = '') =>
                  symbolRegex.test(value) ||
                  tFeatures('validations.password.symbol'),
              },
            }}
          />
          <TextFormField
            name="confirmPassword"
            control={control}
            errors={errors.confirmPassword}
            label={tFeatures('labels.confirmPassword')}
            type="password"
            placeholder={tFeatures('placeholders.confirmPassword')}
            rules={{
              validate: (value) =>
                value === getValues('password') ||
                tFeatures('validations.passwordDoNotMatch'),
            }}
          />
          <CountryFormSelect
            errors={errors.country}
            control={control}
            name="country"
            placeholder={tFeatures('placeholders.country')}
            label={tFeatures('labels.country')}
            rules={{
              validate: (value) => {
                return (
                  isValidCountry(value || '') ||
                  tFeatures('validations.validCountry')
                )
              },
            }}
          />
          <AppButton
            type="submit"
            variant="contained"
            fullWidth
            loading={isPending}
          >
            {tFeatures('title')}
          </AppButton>
        </Box>
      </Box>
    </Box>
  )
}
