import {
  AfterPayIcon,
  KlarnaIcon,
  PayPalIcon,
  SezzleIcon,
  ShoppayIcon,
} from 'entities/assets'

export const paymentMethods = [
  {
    key: 'paypal',
    icon: PayPalIcon,
  },
  {
    key: 'afterPay',
    icon: AfterPayIcon,
  },
  {
    key: 'sezzle',
    icon: SezzleIcon,
  },
  {
    key: 'shopPay',
    icon: ShoppayIcon,
  },
  {
    key: 'klarna',
    icon: KlarnaIcon,
  },
]
