import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query'

import { SnackbarOptions } from 'shared/components/snackbar-notification-management/snackbar-context'

import { ErrorResponse } from 'entities/definitions'

/**
 * FIXME: Add any global logic as per your API response - {NetworkErrorResponse}. example: logout user on invalid token
 * TODO: Global error toast notification
 */
export function initQueryClient(
  showSnackbar: (options: SnackbarOptions) => void
): QueryClient {
  return new QueryClient({
    queryCache: new QueryCache({
      onError: (error) => {
        const newError = error as ErrorResponse
        // eslint-disable-next-line no-console
        showSnackbar({
          message: newError.response?.data.message || '',
          type: 'error',
        })
        console.log('global query err', error as ErrorResponse)
        return error
      },
    }),
    mutationCache: new MutationCache({
      onError: (error) => {
        const newError = error as ErrorResponse
        // eslint-disable-next-line no-console
        showSnackbar({
          message: newError.response?.data.message || '',
          type: 'error',
        })
        console.log('global mutation err', error as ErrorResponse)
        return error
      },
    }),
    defaultOptions: {
      queries: {
        retry: false, // Set to true to retry failed queries
        staleTime: Infinity,
        refetchOnWindowFocus: false,
      },
      mutations: {
        retry: false,
      },
    },
  })
}
