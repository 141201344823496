import { createStyles } from 'shared/utils'

const styles = createStyles({
  wrapper: {
    display: 'flex',
    padding: '100px 70px',
    paddingInline: 'clamp(50px, 10%, 150px)',
    minHeight: '100vh',
    backgroundColor: 'custom.background.light',
    color: 'text.primary',
    position: 'relative',
    gap: '20px',
    zIndex: '1',
    '&::before': {
      content: '""',
      position: 'absolute',
      inset: 0,
      background: 'linear-gradient(163.85deg, #43CBFF 0%, #9708CC 90.58%)',
      opacity: '0.1',
      zIndex: -1,
    },
  },
  leftSection: {
    flexBasis: '60%',
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '72px',
  },
  notFoundCode: {
    background: 'linear-gradient(163.85deg, #43CBFF 0%, #9708CC 90.58%)',
    WebkitBackgroundClip: 'text',
    backgroundClip: 'text',
    color: 'transparent',
    fontWeight: 'bold',
    fontSize: '72px',
    width: 'max-content',
    margin: '0',
  },
  subheading: {
    fontSize: '36px',
    maxWidth: '650px',
  },
  description: {
    maxWidth: '650px',
  },
  goToHomeButton: {
    marginTop: '20px',
    alignSelf: 'flex-start',
  },
  pandaImage: {
    alignSelf: 'flex-end',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexBasis: '40%',
  },
  panda: {
    animation: 'move 2s infinite',
    '@keyframes move': {
      '0%': {
        transform: 'translateY(0)',
      },
      '50%': {
        transform: 'translateY(10px)',
      },
      '100%': {
        transform: 'translateY(0)',
      },
    },
  },
  pandaShadow: {
    backgroundColor: 'text.secondary',
    width: '50%',
    height: '50px',
    borderRadius: '50%',
    position: 'relative',
    right: '20px',
    top: '20px',
  },
})

export default styles
