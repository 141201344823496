import {
  Controller,
  ControllerProps,
  FieldError,
  FieldValues,
} from 'react-hook-form'

import { CountrySelect, CountrySelectProps } from 'shared/components'

type Props<TFieldValue extends FieldValues> = CountrySelectProps &
  Omit<ControllerProps<TFieldValue>, 'render'> & {
    errors?: FieldError
  }

export function CountryFormSelect<TFieldValue extends FieldValues>({
  control,
  label,
  name,
  errors,
  placeholder,
  ...restProps
}: Props<TFieldValue>) {
  return (
    <Controller
      {...restProps}
      name={name}
      control={control}
      render={({ field }) => (
        <CountrySelect
          placeholder={placeholder}
          {...field}
          label={label}
          errors={errors}
        />
      )}
    />
  )
}
