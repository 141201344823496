import { Box } from '@mui/material'

import { getStyles } from 'shared/utils'

import { ThemesCardImage } from 'entities/assets'

import defaultStyles from './styles'

/**
 *
 * @todo Theme Image
 * Add props
 */
export function ThemesCard() {
  const styles = getStyles(defaultStyles)

  return (
    <Box {...styles('container')}>
      <Box
        component="img"
        src={ThemesCardImage}
        alt="card-image"
        width="100%"
        maxWidth="250px"
      />
      <Box {...styles('themeDetails')}>
        <Box component="span" {...styles('themeName')}>
          Theme Name Goes Here
        </Box>
        <Box {...styles('activeThemeBtn')}>
          <Box {...styles('circle')} />
          Active
        </Box>
      </Box>
    </Box>
  )
}
