import { useEffect, useState } from 'react'

import { Box, ListItem, Typography } from '@mui/material'
import { Link, Outlet, useLocation } from 'react-router-dom'

import { TickIcon } from 'shared/assets'
import { AppButton, DefaultModal } from 'shared/components'
import { useSearchParam } from 'shared/hooks'
import { getStyles } from 'shared/utils'

import {
  AddIcon,
  CreateWithLogo,
  SettingsIcon,
  ShopifyLogo,
  ThemesCardImage,
} from 'entities/assets'
import { navLinks, paths } from 'entities/config'
import { useEntitiesTranslation } from 'entities/locale'
import { useUserSession } from 'entities/state/user/user.session'

import { useFeaturesTranslation } from 'features/locale'
import { useActivePlanQuery } from 'features/state'

import defaultStyles from './styles'

export function DashboardLayout() {
  const [openModal, setOpenModal] = useState<boolean>(false)
  const { tEntities } = useEntitiesTranslation()
  const location = useLocation()
  const { data: activePlanData, isSuccess } = useActivePlanQuery()
  const { tFeatures: tFreeTrial } = useFeaturesTranslation('freeTrialModal')
  const { data } = useUserSession()

  const { embedded } = useSearchParam({ embedded: 'embedded' })
  const isEmbedded = embedded === '1'

  useEffect(() => {
    if (isSuccess) {
      const currentPlans =
        activePlanData.data?.appByHandle?.installation?.activeSubscriptions
      const firstPlan = currentPlans ? currentPlans[0] : null
      // const isPlanFree = firstPlan?.name === 'FREE'
      const planActive = firstPlan?.status === 'ACTIVE'

      const openExpiredModal = !planActive && location.pathname !== paths.plans
      setOpenModal(openExpiredModal)
    }
  }, [activePlanData, isSuccess, location.pathname])

  const closeModal = () => {
    setOpenModal(false)
  }

  const showSidebar = ![paths.plans].includes(location.pathname) && !isEmbedded

  const fullName = `${data?.firstName || 'User'} ${data?.lastName || ''}`
  const styles = getStyles(defaultStyles)

  return (
    <Box {...styles('wrapper')}>
      <Box component="header" {...styles('headerWrapper')}>
        <Box {...styles('leftSection')}>
          <Box
            component="img"
            src={AddIcon}
            alt="add-icon"
            height="32px"
            sx={{ cursor: 'pointer' }}
          />
          <Box
            component="img"
            src={CreateWithLogo}
            alt="logo"
            height="22px"
            {...styles('createWithLogo')}
          />
          <Box className="center" {...styles('greetings')}>
            <Box component="span">{tEntities('commonHeader.greeting')},</Box>
            <Box component="span" {...styles('userName')}>
              &nbsp;{fullName}
            </Box>
          </Box>
        </Box>
        <Box {...styles('rightSection')}>
          <Box component="span" {...styles('profileIcon')}>
            <Box
              component="img"
              src={ShopifyLogo}
              sx={{ objectFit: 'cover' }}
              width="33px"
              height="33px"
            />
          </Box>
          <AppButton
            label={tEntities('commonHeader.launchLabel')}
            component="button"
            buttonStyle="gradient"
          />
        </Box>
      </Box>{' '}
      <Box
        {...(styles('childWrapper'),
        { paddingLeft: showSidebar ? '90px' : '0' })}
      >
        {showSidebar && (
          <Box component="nav" {...styles('navbar')}>
            {navLinks.map(({ icon, tKey, href }) => {
              return (
                <ListItem
                  {...styles('navLink')}
                  component={Link}
                  to={href}
                  key={tKey}
                >
                  <Box
                    component="img"
                    src={icon}
                    alt={tKey}
                    {...styles(
                      location.pathname === href
                        ? 'navLinkActive'
                        : 'navLinkIcon'
                    )}
                  />
                  <Typography {...styles('navLinkTitle')} variant="inherit">
                    {tEntities(`navLinkTitles.${tKey}`)}
                  </Typography>
                </ListItem>
              )
            })}
            <Box {...styles('navLink')} component={Link} to="/dashboard">
              <Box component="img" src={SettingsIcon} alt="settings" />
              <Typography {...styles('navLinkTitle')} variant="inherit">
                {tEntities('navLinkTitles.settings')}
              </Typography>
            </Box>
          </Box>
        )}
        <Outlet />
      </Box>
      <DefaultModal
        handleClose={() => setOpenModal(false)}
        show={openModal}
        variant="gradient"
      >
        <Box {...styles('expiredWrapper')}>
          <Box {...styles('themePreview')}>
            <Box component="img" src={ThemesCardImage} width="100%" />
          </Box>
          <Box {...styles('themeDetails')}>
            <Box {...styles('expiredCard')}>
              <Box {...styles('trialEndedTitle')}>
                {tFreeTrial('planExpiredCard.title')}
              </Box>
              <Box {...styles('takeAppNextLevelTitle')}>
                {tFreeTrial('planExpiredCard.subtitle')}
              </Box>
              <Box {...styles('trialEndedDescription')}>
                {tFreeTrial('planExpiredCard.description')}
              </Box>
            </Box>
            <Box {...styles('missingOutCard')}>
              <Box {...styles('missingOutTitle')}>Missing Out</Box>
              <Box {...styles('missingOutItems')}>
                <Box {...styles('featuresItem')}>
                  <Box component="img" alt="tick" src={TickIcon} width={16} />
                  <Box>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  </Box>
                </Box>
                <Box {...styles('featuresItem')}>
                  <Box component="img" alt="tick" src={TickIcon} width={16} />
                  <Box>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  </Box>
                </Box>
                <Box {...styles('featuresItem')}>
                  <Box component="img" alt="tick" src={TickIcon} width={16} />
                  <Box>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  </Box>
                </Box>
                <Box {...styles('featuresItem')}>
                  <Box component="img" alt="tick" src={TickIcon} width={16} />
                  <Box>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  </Box>
                </Box>
                <Box {...styles('featuresItem')}>
                  <Box component="img" alt="tick" src={TickIcon} width={16} />
                  <Box>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  </Box>
                </Box>
                <Box {...styles('featuresItem')}>
                  <Box component="img" alt="tick" src={TickIcon} width={16} />
                  <Box>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  </Box>
                </Box>
              </Box>
            </Box>
            <AppButton
              buttonStyle="gradient"
              to={paths.plans}
              onClick={closeModal}
            >
              {tFreeTrial('buttonText')}
            </AppButton>
          </Box>
        </Box>
      </DefaultModal>
    </Box>
  )
}
