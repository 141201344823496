import {
  Box,
  Checkbox,
  FormControlLabel,
  FormControlLabelProps,
} from '@mui/material'

import { CustomStyles, getStyles } from 'shared/utils'

import defaultStyles, { StylesClassNames } from './styles'

export type CheckBoxProps = Omit<FormControlLabelProps, 'control' | 'label'> & {
  customStyles?: CustomStyles<StylesClassNames>
}
export function CheckBox({ customStyles, ...restProps }: CheckBoxProps) {
  const styles = getStyles(defaultStyles, customStyles)

  return (
    <FormControlLabel
      {...styles('controlLabel')}
      control={<Checkbox {...styles('checkBox')} />}
      label={
        <Box className="gradient-wrapper" {...styles('gradientWrapper')}>
          <Box className="center" {...styles('checkIcon')}>
            &#10003;
          </Box>
        </Box>
      }
      {...restProps}
    />
  )
}
