import { CardActionArea } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'

import { Noop } from 'shared/typescript'
import { CustomStyles, getStyles } from 'shared/utils'

import defaultStyles, { StylesClassNames } from './styles'

export type ProductCardProps = {
  image: string
  title: string
  description?: string
  onClick?: Noop
  customStyles?: CustomStyles<StylesClassNames>
}

export function ProductCard({
  image,
  description,
  title,
  onClick,
  customStyles,
}: ProductCardProps) {
  const styles = getStyles(defaultStyles, customStyles)

  return (
    <Card {...styles('wrapper')}>
      <CardActionArea onClick={onClick} {...styles('cardActionArea')}>
        <CardMedia
          component="img"
          height="140"
          image={image}
          alt={title}
          {...styles('cardMedia')}
        />
        <CardContent>
          <Typography gutterBottom component="div">
            {title}
          </Typography>
          {description && (
            <Typography variant="body2" {...styles('description')}>
              {description}
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
