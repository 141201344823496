import { useEffect, useMemo, useState } from 'react'

import { SnackBar } from './Snackbar'
import {
  SnackbarContext,
  SnackbarContextProps,
  SnackbarOptions,
} from './snackbar-context'

export function SnackbarProvider({ children }: { children: React.ReactNode }) {
  const [snackbar, setSnackbar] = useState<SnackbarOptions | null>(null)

  const dismiss = () => {
    setSnackbar(null)
  }
  const show = (newSnackbar: SnackbarOptions) => {
    setSnackbar(newSnackbar)
  }

  useEffect(() => {
    let timeout: string | number | NodeJS.Timeout | undefined
    if (snackbar) {
      timeout = setTimeout(dismiss, 5000)
    }
    return () => clearTimeout(timeout)
  }, [snackbar])

  const contextValue = useMemo(
    () =>
      ({
        showSnackbar: show,
        dismissSnackbar: dismiss,
        snackbar,
      }) satisfies SnackbarContextProps,
    [snackbar]
  )

  return (
    <SnackbarContext.Provider value={contextValue}>
      {children}
      {snackbar && <SnackBar {...snackbar} dismiss={dismiss} />}
    </SnackbarContext.Provider>
  )
}
