import { forwardRef, useState } from 'react'

import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material'
import SearchIcon from '@mui/icons-material/Search'
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputBase,
  InputBaseProps,
  InputLabel,
} from '@mui/material'

import { CustomStyles, getStyles } from 'shared/utils'

import defaultStyles, { StyleClassNames } from './styles'

export type TextFieldProps = InputBaseProps & {
  label?: string
  helperText?: string
  type?: 'text' | 'search' | 'password' | 'email' | 'number' | 'tel'
  customStyles?: CustomStyles<StyleClassNames>
}

type InputType = TextFieldProps['type']

export const TextField = forwardRef(function TextField(
  {
    label,
    type = 'text',
    fullWidth = true,
    readOnly = false,
    helperText,
    customStyles,
    error = false,
    ...restTextFieldProps
  }: TextFieldProps,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const [showPassword, setShowPassword] = useState<boolean>(false)

  const ActiveVisibilityIcon = showPassword
    ? VisibilityOffOutlined
    : VisibilityOutlined
  const styles = getStyles(defaultStyles, customStyles)

  const getType = (type: InputType): InputType => {
    if (type === 'search') {
      return 'text'
    }
    if (type === 'password' && showPassword) {
      return 'text'
    }

    return type
  }

  return (
    <FormControl
      fullWidth={fullWidth}
      variant="standard"
      {...styles('wrapper', {
        color: readOnly ? 'text.secondary' : 'initial',
      })}
    >
      {label && (
        <InputLabel
          shrink
          {...styles('label', {
            color: readOnly ? 'text.secondary' : 'initial',
          })}
          htmlFor={label}
        >
          {label}
        </InputLabel>
      )}
      <InputBase
        id={label}
        ref={ref}
        type={getType(type)}
        startAdornment={
          type === 'search' ? (
            <InputAdornment
              aria-label="Search Icon"
              {...styles('adornment')}
              position="start"
            >
              <SearchIcon />
            </InputAdornment>
          ) : undefined
        }
        readOnly={readOnly}
        error={error}
        {...restTextFieldProps}
        {...styles('input', {
          color: readOnly ? 'text.secondary' : 'initial',
        })}
      />
      {type === 'password' && (
        <ActiveVisibilityIcon
          onClick={() => setShowPassword(!showPassword)}
          {...styles('visibilityIcons')}
        />
      )}
      {!!helperText && (
        <FormHelperText
          {...styles('helperText', {
            color: error ? 'error.main' : 'text.secondary',
          })}
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  )
})
