import { I18nKeys } from 'shared/utils'

import {
  AccountsIcon,
  BrandingIcon,
  DeepLinkIcon,
  NotificationIcon,
  PluginsIcon,
  ScreenIcon,
  ThemesIcon,
} from 'entities/assets'
import { EntitiesTranslation } from 'entities/locale'

import { paths } from './path.config'

type NavLinks = {
  href: string
  tKey: I18nKeys<EntitiesTranslation['navLinkTitles']>
  icon: string
}[]

export const navLinks: NavLinks = [
  {
    href: paths.dashboard,
    tKey: 'screens',
    icon: ScreenIcon,
  },
  {
    href: paths.themes,
    tKey: 'themes',
    icon: ThemesIcon,
  },
  {
    href: paths.branding,
    tKey: 'branding',
    icon: BrandingIcon,
  },
  {
    href: paths.notifications,
    tKey: 'notifications',
    icon: NotificationIcon,
  },
  {
    href: paths.deeplink,
    tKey: 'deeplink',
    icon: DeepLinkIcon,
  },
  {
    href: paths.plugins,
    tKey: 'plugins',
    icon: PluginsIcon,
  },
  {
    href: paths.account,
    tKey: 'account',
    icon: AccountsIcon,
  },
]
