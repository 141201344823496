import { GraphQLClient } from 'graphql-request'

export const getGraphQlClient = () => {
  const endPoint = process.env.REACT_APP_SHOPIFY_BASE_URL ?? ''
  const token = process.env.REACT_APP_SHOPIFY_TOKEN ?? ''

  const graphQLClient = new GraphQLClient(endPoint, {
    headers: {
      'X-Shopify-Storefront-Access-Token': token,
      'Content-Type': 'application/json',
    },
  })

  return graphQLClient
}
