import { brandingEn as branding } from './branding.en'
import { defaultEn } from './features.en'
import { modalsEn as modals } from './modals.en'
import { settingsEn as settings } from './settings.en'

export const featuresEn = {
  ...defaultEn,
  settings,
  branding,
  modals,
}
