import { IClient, getClient } from 'shared/data-providers'

import { UpdateUserPayload, UserResponse } from 'entities/model'

export class UserAPI {
  private client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  async getUser() {
    return this.client.get<UserResponse>('user-info')
  }

  async updateUser(payload: UpdateUserPayload) {
    return this.client.put<UpdateUserPayload, UserResponse>(
      'update-details',
      payload
    )
  }
}

export const userApi = new UserAPI(getClient('api/v1/user'))
