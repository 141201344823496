import { File } from 'buffer'

import { forwardRef } from 'react'

import { Box, InputBase, InputLabel } from '@mui/material'

import { DocumentUploadedIcon, UploadIcon } from 'shared/assets'
import { getStyles } from 'shared/utils'

import defaultStyle from './styles'

export type FileUploadProps = {
  label?: string
  allowedFormat?: string
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  value: File | null
}

/**
 * @component FileUpload
 */

export const FileUpload = forwardRef(function FileUpload(
  { label, allowedFormat = '', handleChange, value = null }: FileUploadProps,
  ref: React.ForwardedRef<HTMLSelectElement>
) {
  const style = getStyles(defaultStyle)

  return (
    <Box width="100%">
      {label && <Box {...style('label')}>{label}</Box>}
      {!value ? (
        <InputLabel {...style('wrapper')} component="label">
          <InputBase
            ref={ref}
            type="file"
            {...style('fileField')}
            inputProps={{ accept: allowedFormat }}
            onChange={handleChange}
          />
          <>
            <Box {...style('titleWrapper')}>
              <Box component="img" src={UploadIcon} alt="upload-icon" />
              <Box {...style('title')}>Upload File</Box>
            </Box>
            <Box {...style('helperText')}> (450px - 90px) Size: 5Mb (max.)</Box>
          </>
        </InputLabel>
      ) : (
        <Box {...style('fileUploadedWrapper')}>
          <Box {...style('leftWrapper')}>
            <Box
              component="img"
              src={DocumentUploadedIcon}
              alt="document-uploaded"
            />
            <Box {...style('fileDetails')}>
              <Box {...style('fileName')} className="ellipsis-text">
                {value.name}
              </Box>
              <Box {...style('fileSize')}>{value.size}</Box>
            </Box>
          </Box>
          <InputLabel {...style('uploadAgain')}>
            Upload Again
            <InputBase
              ref={ref}
              type="file"
              {...style('fileField')}
              inputProps={{ accept: allowedFormat }}
              onChange={handleChange}
            />
          </InputLabel>
        </Box>
      )}
    </Box>
  )
})
