import { StylesClasses, createStyles } from 'shared/utils'

const styles = createStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    borderRadius: '8px',
    border: '1px solid',
    borderColor: 'custom.border.main',
    padding: '0px 20px 35px 20px',
  },
  inputHalf: {
    width: '50%',
  },
  label: {
    fontSize: '1.2rem',
    marginTop: '10px',
  },
  paymentsIcon: {
    position: 'relative',
    top: '18px',
    border: '1px solid',
    borderColor: 'custom.border.main',
    borderRadius: '8px',
    height: '45px',
    minWidth: '94px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '20px',
  },
  checkbox: {
    position: 'relative',
    top: '18px',
  },
})

export type StylesClassNames = StylesClasses<typeof styles>

export default styles
