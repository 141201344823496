import { useCallback, useEffect, useState } from 'react'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { useSnackbar } from 'shared/components'
import { Nullable } from 'shared/typescript'

import { initQueryClient } from '../query-client'

type Props = {
  readonly children: React.ReactNode
}

export function QueryProvider({ children }: Props) {
  const [queryClient, setQueryClient] = useState<Nullable<QueryClient>>(null)

  const { showSnackbar } = useSnackbar()

  const setQueryClientPersistently = useCallback(() => {
    setQueryClient(initQueryClient(showSnackbar))
  }, [])

  useEffect(() => {
    setQueryClientPersistently()
  }, [setQueryClientPersistently])

  if (!queryClient) {
    return null
  }

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools buttonPosition="bottom-left" initialIsOpen={false} />
    </QueryClientProvider>
  )
}
