import { GraphQLClient, gql } from 'graphql-request'

import { getGraphQlClient } from 'shared/data-providers'

import {
  CollectionsResponse,
  ProductTagList,
  ProductTypesResponse,
  ProductsResponse,
} from 'entities/model/query'
import { collections, productTags, productTypeQuery } from 'entities/queries'

type Query = ReturnType<typeof gql>

export class GraphQlAPI {
  private client: GraphQLClient

  constructor(client: GraphQLClient) {
    this.client = client
  }

  async productTypeRequest() {
    return this.client.request<ProductTypesResponse>(productTypeQuery)
  }

  async productRequest(query: Query) {
    return this.client.request<ProductsResponse>(query)
  }

  async productTagsRequest() {
    return this.client.request<ProductTagList>(productTags)
  }

  async collectionsRequest() {
    return this.client.request<CollectionsResponse>(collections)
  }
}

export const graphQlApi = new GraphQlAPI(getGraphQlClient())
