import { Box, Typography } from '@mui/material'

import { AppButton } from 'shared/components'
import { getStyles } from 'shared/utils'

import { NotFoundPanda } from 'features/assets'
import { useFeaturesTranslation } from 'features/locale'

import defaultStyles from './styles'

export function NotFound() {
  const { tFeatures } = useFeaturesTranslation('notFound')
  const styles = getStyles(defaultStyles)

  return (
    <Box {...styles('wrapper')}>
      <Box {...styles('leftSection')}>
        <Box {...styles('heading')}>
          <Box {...styles('notFoundCode')} component="h1">
            {tFeatures('heading')}
          </Box>
          <Box component="span">{tFeatures('emoji')}</Box>
        </Box>
        <Box {...styles('subheading')}>{tFeatures('subheading')}</Box>
        <Typography component="p" {...styles('description')}>
          {tFeatures('description')}
        </Typography>
        <AppButton variant="contained" to="/" {...styles('goToHomeButton')}>
          {tFeatures('buttonLabel')}
        </AppButton>
      </Box>
      <Box {...styles('pandaImage')}>
        <Box
          component="img"
          src={NotFoundPanda}
          alt="not-found"
          width="80%"
          maxWidth="400px"
          minWidth="250px"
          {...styles('panda')}
        />
        <Box {...styles('pandaShadow')} />
      </Box>
    </Box>
  )
}
