import { StylesClasses, createStyles } from 'shared/utils'

const styles = createStyles({
  wrapper: {
    width: '100%',
    padding: '30px 10px',
    border: '1px solid',
    borderColor: 'text.secondary',
    borderRadius: '8px',
    borderStyle: 'dashed',
    color: 'text.secondary',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'custom.background.light',
    position: 'relative',
    cursor: 'pointer',
  },
  titleWrapper: {
    display: 'flex',
    gap: '5px',
    marginBottom: '5px',
    justifyContent: 'center',
  },
  title: {
    color: 'custom.darkblue',
    fontSize: '14px',
  },
  helperText: {
    fontSize: '12px',
  },
  label: {
    fontSize: '14px',
    marginBottom: '8px',
    fontWeight: '500',
  },
  fileField: {
    position: 'absolute',
    visibility: 'hidden',
  },

  // File uploaded styles

  fileUploadedWrapper: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid',
    borderColor: 'custom.border.main',
    padding: '12px 5px',
    borderRadius: '8px',
    justifyContent: 'center',
  },
  leftWrapper: {
    display: 'flex',
    gap: '8px',
    maxWidth: '70%',
  },
  fileDetails: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '70%',
  },
  fileName: {
    color: 'custom.text.light',
    fontSize: '14px',
    fontWeight: '500',
  },
  fileSize: {
    color: 'text.secondary',
    fontSize: '11px',
  },
  uploadAgain: {
    width: 'max-content',
    color: 'custom.darkblue',
    cursor: 'pointer',
    margin: '0',
    fontSize: '14px',
    marginLeft: 'auto !important',
    whiteSpace: 'normal',
    textAlign: 'center',
  },
})

export type StylesClassNames = StylesClasses<typeof styles>

export default styles
