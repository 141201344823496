import { createStyles, StylesClasses } from 'shared/utils'

const styles = createStyles({
  wrapper: {
    width: '100%',
  },
  tabsWrapper: {
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    alignItems: 'center',
  },
  tab: {
    padding: '6px 12px',
    '&.Mui-selected': {
      backgroundColor: 'custom.background.light',
      borderRadius: '8px',
    },
    '&.MuiButtonBase-root': {
      minHeight: 'auto',
      height: '30px',
      alignSelf: 'center',
      textTransform: 'none',
    },
    '&.MuiTabs-scroller': {
      alignItems: 'center',
    },
  },

  chip: {
    fontSize: '12px',
    height: '20px',
    backgroundColor: 'custom.lightblue',
  },
})

export type StylesClassNames = StylesClasses<typeof styles>

export default styles
