import { Box } from '@mui/material'
import { useForm } from 'react-hook-form'

import { SelectFormField, TextFormField } from 'shared/form'
import { CustomStyles, getStyles } from 'shared/utils'

import { useFeaturesTranslation } from 'features/locale'

import { sortCollectionOptions } from '../../../constants'

import defaultStyles, { StylesClassNames } from './styles'

type CollectionsProps = {
  customStyles?: CustomStyles<StylesClassNames>
}

type CollectionForm = {
  title: string
  sort: string
}

export function Collections({ customStyles }: CollectionsProps) {
  const { tFeatures } = useFeaturesTranslation('deeplink.collection')
  const styles = getStyles(defaultStyles, customStyles)

  const { control } = useForm<CollectionForm>({
    mode: 'onTouched',
    defaultValues: {
      title: '',
      sort: '',
    },
  })

  return (
    <Box {...styles('wrapper')}>
      <Box {...styles('leftWrapper')}>
        <Box {...styles('fieldWrapper')}>
          <TextFormField
            name="title"
            label={tFeatures('form.title')}
            control={control}
            placeholder={tFeatures('form.titlePlaceholder')}
            type="search"
            startAdornment={
              <Box
                component="img"
                src="/assets/icons/search.svg"
                alt="search-icon"
                {...styles('searchIcon')}
              />
            }
          />
        </Box>
        <Box {...styles('fieldWrapper')}>
          <SelectFormField
            name="sort"
            label={tFeatures('form.sort')}
            control={control}
            placeholder={tFeatures('form.sortPlaceholder')}
            options={sortCollectionOptions}
          />
        </Box>
      </Box>
      <Box {...styles('rightWrapper')} />
    </Box>
  )
}
