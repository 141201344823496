import { StylesClasses, createStyles } from 'shared/utils'

const styles = createStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    borderTop: '1px solid',
    borderColor: 'custom.border.main',
    paddingBlock: '15px',
    gap: '5px',
  },
  titleSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontWeight: '500',
  },
  description: {
    fontSize: '14px',
    color: 'text.secondary',
  },
})

export type StylesClassNames = StylesClasses<typeof styles>

export default styles
