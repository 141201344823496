import { Box } from '@mui/material'
import { useForm } from 'react-hook-form'

import { AppButton } from 'shared/components'
import { TextFormField } from 'shared/form'
import { emailRegex, getStyles } from 'shared/utils'

import { paths } from 'entities/config'
import { SignInPayload } from 'entities/model'

import { useFeaturesTranslation } from 'features/locale'
import { useSignInMutation } from 'features/state'

import { ShopifyLogin } from '../ShopifyLogin'

import defaultStyles from './styles'

type FormData = SignInPayload

export function SignInForm() {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      email: '',
      password: '',
    },
    criteriaMode: 'firstError',
    mode: 'onBlur',
  })

  const { mutate, isPending } = useSignInMutation()

  const onSubmit = (formData: FormData) => {
    mutate(formData)
  }

  const { tFeatures } = useFeaturesTranslation('signIn')
  const styles = getStyles(defaultStyles)

  return (
    <Box {...styles('container')}>
      <Box width="100%">
        <Box component="div" {...styles('heading')}>
          {tFeatures('title')}
        </Box>
        <Box component="p" {...styles('caption')}>
          {tFeatures('subtitle')}
        </Box>
        <Box
          component="form"
          {...styles('form')}
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextFormField
            control={control}
            name="email"
            errors={errors.email}
            label={tFeatures('labels.email')}
            placeholder={tFeatures('placeholders.email')}
            type="email"
            rules={{
              required: {
                value: true,
                message: tFeatures('validations.email.required'),
              },
              pattern: {
                value: emailRegex,
                message: tFeatures('validations.email.valid'),
              },
            }}
          />
          <Box>
            <TextFormField
              name="password"
              control={control}
              label={tFeatures('labels.password')}
              type="password"
              errors={errors.password}
              rules={{
                required: {
                  value: true,
                  message: tFeatures('validations.passwordRequired'),
                },
              }}
              placeholder={tFeatures('placeholders.password')}
            />
          </Box>
          <AppButton type="submit" variant="contained" loading={isPending}>
            {tFeatures('title')}
          </AppButton>
        </Box>
        <AppButton
          variant="text"
          to={paths.forgotPassword}
          {...styles('helperText')}
        >
          {tFeatures('helperText')}
        </AppButton>
        <Box component="small" {...styles('orText')}>
          {tFeatures('orText')}
        </Box>
        <ShopifyLogin />
      </Box>
    </Box>
  )
}
