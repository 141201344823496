import { CollectionNode, Edge } from '../model/query'

export const selectFieldOptionEdgeTransformer = (data: Edge[] | undefined) => {
  if (!data) return []
  return data
    .filter((item) => item.node)
    .map((item) => ({ label: item.node, value: item.node }))
}

export const selectFieldOptionTransformer = (
  data: CollectionNode[] | undefined
) => {
  if (!data) return []
  return data
    .filter((item) => item.title)
    .map((item) => ({ label: item.title, value: item.title }))
}
