import { ReactNode } from 'react'

import { Button, ButtonProps, CircularProgress } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

import { CustomStyles, getStyles } from 'shared/utils'

import defaultStyles, { StyleClassNames } from './styles'

export type ComponentType = 'Link' | 'button' // Define available component types
export type ButtonStyle = 'basic' | 'gradient'

const sizes = {
  small: 21,
  medium: 23,
  large: 25,
}

export type AppButtonProps<T extends ComponentType, U extends ButtonStyle> = {
  label?: string
  customStyles?: CustomStyles<StyleClassNames>
  component?: T
  size?: keyof typeof sizes
  buttonStyle?: U
  loading?: boolean
  children?: ReactNode
} & (T extends 'Link'
  ? { to: string }
  : T extends 'button'
    ? ButtonProps
    : never)

export function AppButton<T extends ComponentType, U extends ButtonStyle>({
  label,
  buttonStyle: type,
  children,
  loading = false,
  size = 'medium',
  customStyles,

  ...rest
}: AppButtonProps<T, U>) {
  const styles = getStyles(defaultStyles, customStyles)

  return (
    <Button
      disabled={loading}
      size={size}
      LinkComponent={RouterLink}
      {...styles(type === 'gradient' ? 'gradientBtn' : 'wrapper')}
      {...rest}
    >
      {loading ? (
        <CircularProgress color="inherit" size={sizes[size]} />
      ) : (
        children ?? label
      )}
    </Button>
  )
}
