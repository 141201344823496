import { StylesClasses, createStyles } from 'shared/utils'

const styles = createStyles({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
    marginTop: '50px',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  heading: {
    fontWeight: '500',
    fontSize: '30px',
  },
  caption: {
    fontWeight: 'light',
    marginBlock: '0',
    color: 'text.secondary',
  },
  helperText: {
    fontSize: '14px',
    marginTop: '10px',
    marginBottom: '15px',
    display: 'block',
    textAlign: 'center',
    color: 'text.secondary',
  },
  orText: {
    color: 'text.secondary',
    display: 'block',
    marginBlock: '15px',
    textAlign: 'center',
  },
  fullWidth: {},
})

export type StylesClassNames = StylesClasses<typeof styles>

export default styles
