import {
  Controller,
  ControllerProps,
  ControllerRenderProps,
  FieldValues,
} from 'react-hook-form'

import { FileUpload, FileUploadProps } from 'shared/components'

type Props<TFormValues extends FieldValues> = Omit<
  FileUploadProps,
  keyof ControllerRenderProps
> &
  Omit<ControllerProps<TFormValues>, 'render'>

export function FileUploadFormField<TFormValues extends FieldValues>({
  name,
  control,
  rules,
  shouldUnregister,
  ...restFileUploadProps
}: Props<TFormValues>) {
  return (
    <Controller
      render={({ field }) => {
        return (
          <FileUpload
            {...restFileUploadProps}
            {...field}
            handleChange={(e) => {
              const fileList = e.target.files
              if (fileList && fileList.length > 0) {
                const selectedFile = fileList[0]
                return field.onChange(selectedFile)
              }
              return null
            }}
          />
        )
      }}
      control={control}
      name={name}
      rules={rules}
      shouldUnregister={shouldUnregister}
    />
  )
}
