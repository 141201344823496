import { Alert, Snackbar } from '@mui/material'

import { SnackbarOptions } from './snackbar-context'

type Props = SnackbarOptions & {
  dismiss: () => void
}

export function SnackBar({ type = 'info', message, dismiss }: Props) {
  return (
    <Snackbar
      open={!!message}
      autoHideDuration={5000}
      onClose={dismiss}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert
        onClose={dismiss}
        severity={type}
        variant="outlined"
        sx={{ width: '100%' }}
      >
        {message}
      </Alert>
    </Snackbar>
  )
}
