import { createStyles } from 'shared/utils'

const styles = createStyles({
  wrapper: {
    backgroundColor: 'white',
    padding: '130px',
    zIndex: '2',
    position: 'relative',

    '&::before': {
      background:
        'linear-gradient(135deg, rgb(67 203 255 / 10%) 0%, rgb(151 8 204 / 10%) 100%)',
      content: '""',
      position: 'absolute',
      inset: 0,
      zIndex: '-1',
    },
  },
  pricingTag: {
    backgroundColor: 'success.main',
    padding: '5px 12px',
    borderRadius: '25px',
    width: 'fit-content',
    fontSize: '12px',
    fontWeight: '500',
    color: 'common.white',
    margin: 'auto',
    marginBottom: '20px',
  },
  heading: {
    fontSize: '36px',
    fontWeight: '700',
    textAlign: 'center',
  },
  plans: {
    display: 'flex',
    gap: '30px',
    justifyContent: 'center',
    marginTop: '80px',
    flexWrap: 'wrap',
  },
  gradientText: {
    backgroundImage: ' linear-gradient(135deg, #43CBFF 0%, #9708CC 100%)',
    WebkitTextFillColor: 'transparent',
    backgroundClip: 'text',
  },
  tabSelectionWrapper: {
    display: 'flex',
    borderRadius: '25px',
    padding: '3px',
    backgroundColor: 'common.white',
    width: 'max-content',
    marginInline: 'auto',
    marginBlock: '30px',
    position: 'relative',
  },
  tabButton: {
    padding: '4px 15px',
    borderRadius: '25px',
    fontWeight: '500',
    cursor: 'pointer',
  },
  tabActive: {
    background: ' linear-gradient(135deg, #43CBFF 0%, #9708CC 100%)',
    color: 'common.white',
    padding: '4px 15px',
    borderRadius: '25px',
    fontWeight: '500',
    cursor: 'pointer',
  },
  discount: {
    position: 'absolute',
    left: '98%',
  },
})

export default styles
