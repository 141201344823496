import { useEffect } from 'react'

import { Box } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { Outlet, useLocation } from 'react-router-dom'

import { storage } from 'shared/utils'

import { paths } from 'entities/config'
import {
  UserData,
  UserKey,
  UserSessionKey,
} from 'entities/state/user/user.session'

import { useUserInfoQuery } from 'features/state'

export function RootLayout() {
  const { refetch } = useUserInfoQuery()
  const queryClient = useQueryClient()
  const location = useLocation()

  const nonPrivateRoutes = [
    paths.signIn,
    paths.signUp,
    paths.forgotPassword,
    paths.resetPassword,
  ]

  const isNotAuthRoute = !nonPrivateRoutes.includes(location.pathname)

  useEffect(() => {
    if (isNotAuthRoute) {
      const refetchUser = async () => {
        const { data } = await refetch()
        const userInfo = data?.data
        queryClient.setQueryData<UserData>(UserSessionKey, userInfo)
        storage.setData(UserKey, userInfo)
      }
      void refetchUser()
    }
  }, [isNotAuthRoute, queryClient, refetch])

  return (
    <Box>
      <Outlet />
    </Box>
  )
}
