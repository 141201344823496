import { StylesClasses, createStyles } from 'shared/utils'

const styles = createStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: 'calc(100vh - 128px)',
  },

  leftWrapper: {
    flexBasis: '25%',
    backgroundColor: 'common.white',
    height: '100%',
    padding: '24px 16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    overflowY: 'auto',
  },

  rightWrapper: {
    flexBasis: '75%',
    height: '100%',
    padding: '24px',
    overflowY: 'auto',
    backgroundColor: 'custom.background.dark',
  },

  searchIcon: {
    marginRight: '8px',
  },

  fieldWrapper: {
    padding: '24px 0',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: 'custom.divider',
  },

  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '16px',
  },

  modalHeading: {
    fontSize: '14px',
    fontWeight: '500',
  },

  deeplinkCopy: {
    borderWidth: '1px',
    borderColor: 'custom.border.main',
    borderStyle: 'solid',
    width: '100%',
    padding: '8px 12px',
    position: 'relative',
    fontSize: '14px',
    borderRadius: '8px',
  },

  deeplink: {
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    paddingRight: '40px',
    maxWidth: '400px',
  },

  copyIcon: {
    position: 'absolute',
    right: '12px',
    top: '8px',
    cursor: 'pointer',
    backgroundColor: 'common.white',
  },

  downloadBtn: {
    width: '100%',
    borderRadius: '8px',
  },

  deeplinkQr: {
    height: '130px',
    width: 'auto',
  },

  products: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
})

export type StylesClassNames = StylesClasses<typeof styles>

export default styles
