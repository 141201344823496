import AddIcon from '@mui/icons-material/Add'
import { Box } from '@mui/material'

import { AppButton } from 'shared/components/AppButton'
import { CustomStyles, getStyles } from 'shared/utils'

import { ModalWrapperProps } from '../m.types'

import defaultStyles, { StylesClassNames } from './styles'

export type FormModalProps = {
  title: string
  desc: string
  leftBtnLabel?: string
  rightBtnLabel?: string
  customStyles?: CustomStyles<StylesClassNames>
}

export function FormModal({
  children,
  title,
  desc,
  leftBtnLabel,
  rightBtnLabel,
  customStyles,
}: FormModalProps & ModalWrapperProps) {
  const styles = getStyles(defaultStyles, customStyles)

  return (
    <Box {...styles('wrapper')}>
      <Box {...styles('header')}>
        <Box {...styles('headerLeftContent')}>
          <Box {...styles('title')}>{title}</Box>
          <Box {...styles('desc')}>{desc}</Box>
        </Box>
        <Box {...styles('headerRightContent')}>
          {leftBtnLabel && (
            <AppButton
              label={leftBtnLabel}
              variant="outlined"
              startIcon={<AddIcon />}
              {...styles('leftBtn')}
            />
          )}
          {rightBtnLabel && <AppButton label="Save" type="submit" />}
        </Box>
      </Box>
      <Box {...styles('children')}>{children}</Box>
    </Box>
  )
}
