import { IClient, getClient } from 'shared/data-providers'

import {
  ForgotPasswordPayload,
  ForgotPasswordResponse,
  ResetPasswordPayload,
  ResetPasswordResponse,
  SignInPayload,
  SignInResponse,
  ValidateResetTokenPayload,
  ValidateResetTokenResponse,
} from 'entities/model'

export class AuthAPI {
  private client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  async signIn(userDetails: SignInPayload) {
    return this.client.post<SignInPayload, SignInResponse>('login', userDetails)
  }

  async sendForgotPasswordEmail(userDetails: ForgotPasswordPayload) {
    return this.client.post<ForgotPasswordPayload, ForgotPasswordResponse>(
      'forgot-password',
      userDetails
    )
  }

  async validateResetToken(userDetails: ValidateResetTokenPayload) {
    return this.client.post<
      ValidateResetTokenPayload,
      ValidateResetTokenResponse
    >('validate-reset-token', userDetails)
  }

  async resetPassword(userDetails: ResetPasswordPayload) {
    return this.client.put<ResetPasswordPayload, ResetPasswordResponse>(
      'reset-password',
      userDetails
    )
  }
}

export const authApi = new AuthAPI(getClient('api/v1/auth'))
