import { createStyles } from 'shared/utils'

const styles = createStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    button: {
      width: 'max-content',
      margin: 'auto',
    },
    padding: '20px',
  },
  maxWidth: {
    maxWidth: '700px',
  },
  iconWrapper: {
    width: '80px',
    borderRadius: '50%',
    aspectRatio: '1/1',
    backgroundColor: 'custom.background.light',
    margin: 'auto',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    marginTop: '20px',
  },
  message: {
    fontSize: '1.2rem',
    fontWeight: '500',
  },
  subtitle: {
    fontSize: '14px',
    color: 'custom.text.light',
    marginBottom: '30px',
    marginTop: '20px',
  },
})

export default styles
