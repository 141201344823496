import { StylesClasses, createStyles } from 'shared/utils'

const styles = createStyles({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
    marginTop: '50px',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  heading: {
    fontWeight: '500',
    fontSize: '30px',
  },
  caption: {
    fontWeight: 'light',
    marginBlock: '0',
    color: 'text.secondary',
  },
  helperText: {
    fontSize: '14px',
    marginTop: '10px',
    marginBottom: '15px',
    display: 'block',
    width: 'max-content',
    marginInline: 'auto',
    fontWeight: '500',
  },
  orText: {
    color: 'text.secondary',
    display: 'block',
    marginBlock: '15px',
    textAlign: 'center',
    position: 'relative',
    '&::before,&::after': {
      content: '""',
      position: 'absolute',
      top: ' 50%',
      width: ' 46%',
      height: ' 0.5px',
      backgroundColor: 'text.secondary',
      opacity: '0.4',
    },
    '&::before': {
      left: '0',
      transform: 'translateY(-50%)',
    },
    '&::after': {
      right: '0',
      transform: 'translateY(-50%)',
    },
  },
  fullWidth: {},
})

export type StylesClassNames = StylesClasses<typeof styles>

export default styles
