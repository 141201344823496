import CloseIcon from '@mui/icons-material/Close'
import { Box } from '@mui/material'

import { Noop } from 'shared/typescript'
import { CustomStyles, getStyles } from 'shared/utils'

import { ModalWrapperProps } from '../m.types'

import defaultStyles, { StylesClassNames } from './styles'

export type DefaultModalProps = {
  title?: string
  show?: boolean
  desc?: string
  showCloseIcon?: boolean
  showHeader?: boolean
  handleClose?: Noop
  variant?: 'default' | 'gradient'
  customStyles?: CustomStyles<StylesClassNames>
}

export function DefaultModal({
  children,
  show,
  title,
  desc,
  showCloseIcon = true,
  handleClose,
  variant = 'default',
  customStyles,
}: DefaultModalProps & ModalWrapperProps) {
  const styles = getStyles(defaultStyles, customStyles)
  if (show) {
    return (
      <>
        <Box {...styles(variant === 'default' ? 'wrapper' : 'gradientWrapper')}>
          {(title || desc) && (
            <Box {...styles('header')}>
              <Box {...styles('headerLeftContent')}>
                <Box {...styles('title')}>{title}</Box>
                {desc && <Box {...styles('desc')}>{desc}</Box>}
              </Box>
            </Box>
          )}
          {showCloseIcon && (
            <CloseIcon onClick={handleClose} {...styles('closeIcon')} />
          )}
          <Box {...styles('children')}>{children}</Box>
        </Box>
        <Box {...styles('overlay')} />
      </>
    )
  }
}
