import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

import { storage } from 'shared/utils'

import { paths } from 'entities/config'
import { ErrorResponse } from 'entities/definitions'
import { UpdateUserPayload, UpdateUserResponse } from 'entities/model'
import {
  UserData,
  UserKey,
  UserSessionKey,
} from 'entities/state/user/user.session'

import { userApi } from 'features/api'

export const useUpdateUserMutation = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  return useMutation<UpdateUserResponse, ErrorResponse, UpdateUserPayload>({
    mutationFn: async (formData) => {
      const { data } = await userApi.updateUser(formData)
      return data
    },
    onSuccess: (res) => {
      queryClient.setQueryData<UserData>(UserSessionKey, res.data)
      storage.setData(UserKey, res.data)
      navigate(paths.dashboard)
    },
  })
}
