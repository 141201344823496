import { Box } from '@mui/material'

import { TickIcon } from 'shared/assets'
import {
  AppButton,
  RadioButtonGroup,
  SimpleAccordion,
  TextField,
} from 'shared/components'
import { getStyles } from 'shared/utils'

import { ThemesCardImage } from 'entities/assets'
import { SettingsCard, ThemesCard } from 'entities/components'
import { PaymentOptions } from 'entities/components/PaymentOption'

import { LinkIcon, ThemesBg } from 'features/assets'
import { paymentMethods } from 'features/config'
import { useFeaturesTranslation } from 'features/locale'

import defaultStyles from './styles'

export function Themes() {
  const { tFeatures } = useFeaturesTranslation()
  const { tFeatures: tSettings } = useFeaturesTranslation('settings')

  const styles = getStyles(defaultStyles)

  return (
    <Box {...styles('wrapper')}>
      <Box {...styles('themeSelection')}>
        <ThemesCard />
        <ThemesCard />
      </Box>
      <Box {...styles('themeSettings')}>
        <Box>
          <Box {...styles('themeDetailsCard')}>
            <Box>
              <Box {...styles('themeTitle')}>Theme1 Purple</Box>
              <Box {...styles('themeSubtitle')}>
                Lorem ipsum dolor sit amet consectetur. Natoque semper blandit
                senectus tincidunt. Scelerisque adipiscing ut arcu eget rhoncus
                in etiam.
              </Box>
              <Box {...styles('featuresSection')}>
                <Box>Features:</Box>
                <Box {...styles('featureItem')}>
                  <Box component="img" alt="tick" src={TickIcon} width={18} />
                  <Box>Lorem ipsum dolor sit amet.</Box>
                </Box>
                <Box {...styles('featureItem')}>
                  <Box component="img" alt="tick" src={TickIcon} width={18} />
                  <Box>Lorem ipsum dolor sit amet.</Box>
                </Box>
              </Box>
            </Box>
            <Box {...styles('themeVisualRepContainer')}>
              <Box component="img" src={ThemesBg} alt="themes-bg" />
              <Box
                component="img"
                src={ThemesCardImage}
                alt="theme"
                {...styles('themeImage')}
              />
            </Box>
          </Box>
        </Box>
        <SimpleAccordion label={tFeatures('themeSettingsLabel.inventory')}>
          <SettingsCard
            title={tSettings('inventory.storePickup.title')}
            description={tSettings('inventory.storePickup.desc')}
          />
          <SettingsCard
            title={tSettings('inventory.preventPurchaseWhenOutOfStock.title')}
            description={tSettings(
              'inventory.preventPurchaseWhenOutOfStock.desc'
            )}
          />
          <SettingsCard
            title={tSettings('inventory.limitAppUsage.title')}
            description={tSettings('inventory.limitAppUsage.desc')}
          />
        </SimpleAccordion>
        <SimpleAccordion label={tFeatures('themeSettingsLabel.productCard')}>
          <SettingsCard
            title={tSettings('productCard.multipleImage.title')}
            description={tSettings('productCard.multipleImage.desc')}
          />
          <SettingsCard
            title={tSettings('productCard.variantCount.title')}
            description={tSettings('productCard.variantCount.desc')}
          />
          <SettingsCard
            title={tSettings('productCard.displayOffers.title')}
            description={tSettings('productCard.displayOffers.desc')}
          />
          <SettingsCard
            title={tSettings('productCard.comparePrices.title')}
            description={tSettings('productCard.comparePrices.desc')}
          />
          <SettingsCard
            title={tSettings('productCard.addToBasketBtn.title')}
            description={tSettings('productCard.addToBasketBtn.desc')}
          />
          <SettingsCard
            title={tSettings('productCard.adjustImage.title')}
            description={tSettings('productCard.adjustImage.desc')}
            showSwitch={false}
          >
            <RadioButtonGroup
              options={[
                { label: 'Portrait', value: 'portrait' },
                { label: 'Landscape', value: 'landscape' },
                { label: 'Square', value: 'square' },
              ]}
            />
          </SettingsCard>
          <SettingsCard
            title={tSettings('productCard.fillMode.title')}
            description={tSettings('productCard.fillMode.desc')}
            showSwitch={false}
          >
            <RadioButtonGroup
              options={[
                { label: 'Fill', value: 'fill' },
                { label: 'Fit', value: 'fit' },
                { label: 'Scale to Fill', value: 'scale-to-fill' },
              ]}
            />
          </SettingsCard>
        </SimpleAccordion>
        <SimpleAccordion label={tFeatures('themeSettingsLabel.help')}>
          <SettingsCard
            title={tFeatures('settings.help.supportEmail.title')}
            description={tFeatures('settings.help.supportEmail.desc')}
          >
            <TextField
              customStyles={{
                input: defaultStyles.inputHalf,
                label: defaultStyles.label,
              }}
              label="Support Email"
              placeholder="Enter Your Support Email"
              type="email"
            />
          </SettingsCard>
          <SettingsCard
            title={tFeatures('settings.help.faqURL.title')}
            description={tFeatures('settings.help.faqURL.desc')}
          >
            <TextField
              customStyles={{
                input: defaultStyles.inputHalf,
                label: defaultStyles.label,
              }}
              label="FAQ URL"
              placeholder="Enter Your Optional Messenger ID"
            />
          </SettingsCard>
        </SimpleAccordion>
        <SimpleAccordion label={tFeatures('themeSettingsLabel.share')}>
          <SettingsCard
            title={tFeatures('settings.share.customShareURL.title')}
            description={tFeatures('settings.share.customShareURL.desc')}
          >
            <TextField
              customStyles={{
                input: defaultStyles.inputHalf,
                label: defaultStyles.label,
              }}
              label="Custom URL"
              placeholder="Add Custom URL Link"
            />
          </SettingsCard>

          <SettingsCard
            title={tFeatures('settings.share.shareText.title')}
            description={tFeatures('settings.share.shareText.desc')}
          >
            <TextField
              customStyles={{
                label: defaultStyles.label,
              }}
              label="Share Text"
              placeholder="Add Your Share Text"
            />
          </SettingsCard>
        </SimpleAccordion>
        <SimpleAccordion
          label={tFeatures('themeSettingsLabel.productDisplayPage')}
        >
          <SettingsCard
            title={tFeatures('settings.productDisplay.priceWithTax.title')}
            description={tFeatures('settings.productDisplay.priceWithTax.desc')}
          />
          <SettingsCard
            title={tFeatures('settings.productDisplay.adjustImageSize.title')}
            description={tFeatures(
              'settings.productDisplay.adjustImageSize.desc'
            )}
            showSwitch={false}
          >
            <RadioButtonGroup
              options={[
                { label: 'Portrait', value: 'portrait' },
                { label: 'Landscape', value: 'landscape' },
                { label: 'Square', value: 'square' },
              ]}
            />
          </SettingsCard>
          <SettingsCard
            title={tFeatures('settings.productDisplay.imageFillMode.title')}
            description={tFeatures(
              'settings.productDisplay.imageFillMode.desc'
            )}
            showSwitch={false}
          >
            <RadioButtonGroup
              options={[
                { label: 'Fill', value: 'fill' },
                { label: 'Fit', value: 'fit' },
                { label: 'Scale to Fill', value: 'scale-to-fill' },
              ]}
            />
          </SettingsCard>
          <SettingsCard
            title={tFeatures('settings.productDisplay.payLaterOptions.title')}
            description={tFeatures(
              'settings.productDisplay.payLaterOptions.desc'
            )}
            showSwitch={false}
          >
            <Box {...styles('paymentOptionsWrapper')}>
              <Box>PAYMENT OPTIONS</Box>
              <Box {...styles('gateWayList')}>
                {paymentMethods.map(({ icon, key }) => {
                  return <PaymentOptions paymentIcon={icon} key={key} />
                })}
              </Box>
            </Box>
          </SettingsCard>
          <SettingsCard
            title={tFeatures('settings.productDisplay.universalTabs.title')}
            description={tFeatures(
              'settings.productDisplay.universalTabs.desc'
            )}
          >
            <Box {...styles('addTabWrapper')}>
              <Box {...styles('addTabHeaderSection')}>
                <Box {...styles('addTabHeading')}>
                  {tSettings(
                    'productDisplay.universalTabs.showTabOnProductPages.heading'
                  )}
                </Box>
                <Box {...styles('addTabSubheading')}>
                  {tSettings(
                    'productDisplay.universalTabs.showTabOnProductPages.subheading'
                  )}
                </Box>
              </Box>
              <Box>
                <Box component="button" {...styles('linkButton')}>
                  <Box component="img" src={LinkIcon} />
                </Box>
              </Box>
              <Box {...styles('linkDescription')}>
                <Box {...styles('linkDescriptionTitle')}>
                  {tSettings(
                    'productDisplay.universalTabs.showTabOnProductPages.addTabs'
                  )}
                </Box>
                <Box {...styles('linkDescriptionSubtitle')}>
                  {tSettings(
                    'productDisplay.universalTabs.showTabOnProductPages.addTabsDesc'
                  )}
                </Box>
              </Box>
              <AppButton variant="outlined">
                +&nbsp;
                {tSettings(
                  'productDisplay.universalTabs.showTabOnProductPages.buttonLabel'
                )}
              </AppButton>
            </Box>
          </SettingsCard>
        </SimpleAccordion>
        <SimpleAccordion label={tFeatures('themeSettingsLabel.searchPage')}>
          <SettingsCard
            title={tFeatures(
              'settings.searchPage.displayRecentlyViewedProducts.title'
            )}
            description={tFeatures(
              'settings.searchPage.displayRecentlyViewedProducts.desc'
            )}
          />
        </SimpleAccordion>
        <SimpleAccordion label={tFeatures('themeSettingsLabel.menuConfig')}>
          <SettingsCard
            title={tFeatures('settings.menuConfiguration.showWishlist')}
          />
          <SettingsCard
            title={tFeatures('settings.menuConfiguration.showRegion')}
          />
          <SettingsCard
            title={tFeatures('settings.menuConfiguration.showHelp')}
          />
          <SettingsCard
            title={tFeatures('settings.menuConfiguration.showLocateUs')}
          />
          <SettingsCard
            title={tFeatures('settings.menuConfiguration.showAboutUs')}
          />
          <SettingsCard
            title={tFeatures('settings.menuConfiguration.showShare')}
          />
          <Box {...styles('customOptionMenu')}>
            <Box {...styles('customMenu')}>
              {tSettings('menuConfiguration.customMenu.title')}
            </Box>
            <AppButton variant="outlined">
              {tSettings('menuConfiguration.customMenu.buttonLabel')}
            </AppButton>
          </Box>
        </SimpleAccordion>
      </Box>
    </Box>
  )
}
