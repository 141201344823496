import { StylesClasses, createStyles } from 'shared/utils'

const styles = createStyles({
  button: {
    display: 'flex',
    justifyContent: 'center',
    gap: '8px',
    padding: '8px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'custom.border.main',
    fontSize: '14px',
    borderRadius: '8px',
    cursor: 'pointer',
    width: '100%',
    fontFamily: 'GeneralSans',
    fontWeight: '500',
  },
})

export type StylesClassNames = StylesClasses<typeof styles>

export default styles
