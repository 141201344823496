import { StylesClasses, createStyles } from 'shared/utils'

const styles = createStyles({
  controlLabel: {
    '&.MuiFormControlLabel-root': {
      position: 'relative',
    },
  },
  checkBox: {
    '&.MuiCheckbox-root': {
      position: 'absolute',
      zIndex: 2,
      opacity: 0,
      inset: 0,
    },
    '&.MuiCheckbox-root.Mui-checked': {
      '+ .MuiTypography-root .gradient-wrapper': {
        background: 'linear-gradient(135deg, #43CBFF 0%, #9708CC 100%)',
        border: 'none',
      },
    },
  },
  gradientWrapper: {
    width: 18,
    position: 'relative',
    aspectRatio: '1/1',
    background: 'transparent',
    border: '1px solid',
    borderColor: 'custom.text.light',
    boxSizing: 'border-box',
    borderRadius: '4px',
    opacity: 0.9,
  },
  checkIcon: {
    position: 'absolute',
    width: '100%',
    textAlign: 'center',
    fontSize: 18,
    color: 'common.white',
    inset: 0,
    margin: 'auto',
  },
})

export type StylesClassNames = StylesClasses<typeof styles>

export default styles
