import { ReactNode } from 'react'

import { Modal as MuiModal, ModalProps as MuiModalProps } from '@mui/material'

import { Noop } from 'shared/typescript'
import { CustomStyles, getStyles } from 'shared/utils'

import defaultStyles, { StylesClassNames } from './styles'
import { DefaultModal, DefaultModalProps } from './wrapper/DefaultModal'
import { FormModal, FormModalProps } from './wrapper/FormModal'
import { SkipModal, SkipProps } from './wrapper/SkipModal'

const ModalMapping = {
  SkipModal,
  FormModal,
  DefaultModal,
}

type ModalMappingType = typeof ModalMapping

type ModalWrapperTypes = keyof ModalMappingType

type OpenAsProps<T extends ModalWrapperTypes> = { openAs: T }

export type ModalProps = {
  children?: ReactNode
  customStyles?: CustomStyles<StylesClassNames>
  closeOnBackdropClick?: boolean
  handleClose: Noop
} & (
  | (OpenAsProps<'FormModal'> & FormModalProps & MuiModalProps)
  | (OpenAsProps<'SkipModal'> & SkipProps & MuiModalProps)
  | (OpenAsProps<'DefaultModal'> & DefaultModalProps & MuiModalProps)
  | (MuiModalProps & { openAs?: never })
)

// FIXME: Type fix needed

export function Modal({
  children,
  customStyles,
  openAs,
  handleClose,
  closeOnBackdropClick = false,
  ...props
}: ModalProps) {
  const styles = getStyles(defaultStyles, customStyles)
  const ModalWrapper = openAs && ModalMapping[openAs]
  return (
    <MuiModal
      {...props}
      onClose={(_, reason) => {
        if (reason === 'backdropClick' && closeOnBackdropClick) {
          handleClose()
        }
      }}
      {...styles('wrapper')}
    >
      {ModalWrapper ? (
        <ModalWrapper
          title=""
          icon=""
          desc=""
          handleClose={() => handleClose()}
          {...(props as unknown as ModalMappingType[typeof openAs])}
        >
          {children}
        </ModalWrapper>
      ) : (
        children
      )}
    </MuiModal>
  )
}
