import { useQuery } from '@tanstack/react-query'

import { graphQlApi } from '../../api/graph-ql.api'

export const productsTagKey = ['products-tag-key']

export function useGetProductTags() {
  const query = useQuery({
    queryKey: productsTagKey,
    queryFn: async () => {
      const response = await graphQlApi.productTagsRequest()
      return response
    },
  })

  return query
}
