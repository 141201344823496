import { useQuery } from '@tanstack/react-query'

import { graphQlApi } from '../../api/graph-ql.api'
import {
  ProductQueryVariables,
  defaultProductQueryVariables,
  getProductQuery,
} from '../../queries'

export const productsKey = ['products-key']

export function useGetProducts(variables?: ProductQueryVariables) {
  const variableKeys = variables
    ? Object.values(variables).map((item) => item)
    : ''

  const query = useQuery({
    queryKey: [productsKey, variableKeys],
    queryFn: async () => {
      const productQuery = getProductQuery({
        ...defaultProductQueryVariables,
        ...variables,
      })

      const response = await graphQlApi.productRequest(productQuery)
      return response
    },
  })

  return query
}
