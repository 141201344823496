import React from 'react'

import { Box, Switch, Typography } from '@mui/material'

import { getStyles } from 'shared/utils'

import defaultStyles from './styles'

type SettingCardProps = {
  children?: React.ReactNode
  title: string
  description?: string
  showSwitch?: boolean
}

export function SettingsCard({
  children,
  title,
  description,
  showSwitch = true,
}: SettingCardProps) {
  const styles = getStyles(defaultStyles)

  return (
    <Box {...styles('wrapper')}>
      <Box {...styles('titleSection')}>
        <Typography {...styles('title')}>{title}</Typography>
        {showSwitch && <Switch />}
      </Box>
      {description && (
        <Typography {...styles('description')}>{description}</Typography>
      )}
      {children && children}
    </Box>
  )
}
