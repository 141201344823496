import { useMutation } from '@tanstack/react-query'

import { ErrorResponse } from 'entities/definitions'
import {
  CreateSubscriptionPayload,
  CreateSubscriptionResponse,
} from 'entities/model'

import { plansApi } from 'features/api'

export const useCreateSubscriptionMutation = () => {
  return useMutation<
    CreateSubscriptionResponse,
    ErrorResponse,
    CreateSubscriptionPayload
  >({
    mutationFn: async (payload: CreateSubscriptionPayload) => {
      const { data } = await plansApi.createPlan(payload)
      return data
    },
    onSuccess: (res) => {
      window.location.href = res.data.confirmationUrl
    },
  })
}
