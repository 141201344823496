import { StylesClasses, createStyles } from 'shared/utils'

import { LayoutBg } from 'app/assets'

const styles = createStyles({
  wrapper: {
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    position: 'relative',
  },
  createWithLogo: {
    fontWeight: '600',
    position: 'absolute',
    zIndex: 2,
    left: '20px',
    top: '15px',
    color: 'common.white',
    fontSize: '25px',
    filter: 'invert(1)',
  },
  leftSection: {
    typography: 'center',
    flexBasis: '60%',
    minWidth: '50%',
    backgroundImage: `url(${LayoutBg})`,
    backgroundSize: 'cover',
    justifyContent: 'center',
    alignItems: 'center',
    display: {
      xs: 'none',
      sm: 'flex',
    },
  },
  rightSection: {
    flexBasis: {
      sm: '100%',
      md: '40%',
    },
    backgroundColor: 'common.white',
    paddingInline: {
      xs: '20px',
      md: '6% ',
    },
  },
  authContent: {
    width: {
      xs: '100%',
      md: '70%',
    },
    minHeight: {
      xs: '100%',
      md: '80%',
    },
    backgroundColor: 'custom.glass.main',
    backdropFilter: 'blur( 4px )',
    WebkitBackdropFilter: 'blur( 6.5px )',
    borderRadius: '10px',
    padding: {
      xs: '60px 20px',
      md: '50px',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: {
      xs: 'center',
      md: 'flex-start',
    },
  },
  textWrapper: {
    maxWidth: 'max(500px,70%)',
    color: 'common.white',
  },
  heading: {
    fontSize: {
      xs: '22px',
      md: '30px',
    },
    marginBlock: '0',
  },
})

export type StylesClassNames = StylesClasses<typeof styles>

export default styles
