import { IClient, getClient } from 'shared/data-providers'

import {
  ActivePlanResponse,
  CreateSubscriptionPayload,
  CreateSubscriptionResponse,
  PlansDuration,
  PlansResponse,
} from 'entities/model'

export class PlansAPI {
  private client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  async getPlans(duration: PlansDuration) {
    return this.client.get<PlansResponse>(`?duration=${duration}`)
  }

  async getActivePlan() {
    return this.client.get<ActivePlanResponse>('active-subscription')
  }

  async createPlan(payload: CreateSubscriptionPayload) {
    return this.client.post<
      CreateSubscriptionPayload,
      CreateSubscriptionResponse
    >(`create-subscription`, payload)
  }
}

export const plansApi = new PlansAPI(getClient('api/v1/plan'))
