import { createStyles, StylesClasses } from 'shared/utils'

const styles = createStyles({
  gradientBtn: {
    background: 'linear-gradient(135deg, #43CBFF 0%, #9708CC 100%)',
    border: 'none',
    borderRadius: '8px',
    padding: '8px 18px',
    color: 'common.white',
    cursor: 'pointer',
    textDecoration: 'none',
    fontSize: '0.9rem',
  },
  wrapper: {},
})

export type StyleClassNames = StylesClasses<typeof styles>

export default styles
