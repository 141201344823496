import { StylesClasses, createStyles } from 'shared/utils'

const styles = createStyles({
  wrapper: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    maxWidth: '1000px',
    minWidth: '700px',
    backgroundColor: 'common.white',
    borderRadius: '5px',
    zIndex: '4',
    padding: '24px',
  },
  gradientWrapper: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    maxWidth: '1000px',
    minWidth: '700px',
    backgroundColor: 'common.white',
    borderRadius: '5px',
    zIndex: '4',
    padding: '24px',
    '&::before': {
      background:
        'linear-gradient(135deg, rgb(67 203 255 / 10%) 0%, rgb(151 8 204 / 10%) 100%)',
      content: '""',
      position: 'absolute',
      inset: 0,
      zIndex: '-1',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: 'custom.border.light',
    paddingBottom: '16px',
    marginBottom: '16px',
  },
  headerLeftContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
    flexDirection: 'column',
  },
  title: {
    fontWeight: '600',
    fontSize: '16px',
  },
  desc: {
    color: 'custom.text.light',
    fontSize: '14px',
  },
  closeIcon: {
    cursor: 'pointer',
    position: 'absolute',
    top: '10px',
    right: '10px',
  },
  children: {},
  overlay: {
    position: 'fixed',
    inset: 0,
    backgroundColor: 'custom.glass.dark',
    backdropFilter: 'blur(4px)',
    zIndex: '3',
  },
})

export type StylesClassNames = StylesClasses<typeof styles>

export default styles
