import { useQuery } from '@tanstack/react-query'

import { ErrorResponse } from 'entities/definitions'
import {
  ActivePlanResponse,
  PlansDuration,
  PlansResponse,
} from 'entities/model'

import { plansApi } from 'features/api'

export const PlansQueryKey = 'plans-query-key'
export const ActivePlanQueryKey = 'active-plan-query-key'

export const usePlansQuery = (planType: PlansDuration) => {
  return useQuery<PlansResponse, ErrorResponse>({
    queryKey: [PlansQueryKey],
    queryFn: async () => {
      const { data } = await plansApi.getPlans(planType)
      return data
    },
    enabled: false,
  })
}
export const useActivePlanQuery = () => {
  return useQuery<ActivePlanResponse, ErrorResponse>({
    queryKey: [ActivePlanQueryKey],
    queryFn: async () => {
      const { data } = await plansApi.getActivePlan()
      return data
    },
  })
}
