import { useState } from 'react'

import { Box } from '@mui/material'
import { useForm } from 'react-hook-form'

import { AppButton } from 'shared/components'
import {
  ColorPickerFormField,
  FileUploadFormField,
  TextFormField,
} from 'shared/form'
import { getStyles } from 'shared/utils'

import { DashboardLayoutWrapper } from 'entities/components'

import { IconScreen, SplashScreen } from 'features/assets'
import { useFeaturesTranslation } from 'features/locale'

import defaultStyle from './styles'

type FormData = {
  name: string
  icon: File | null
  splashScreen: File | null
  storeLogo: File | null
  primaryColor: string
  secondaryColor: string
}

export const brandsTab = {
  appLogo: 'App Logo',
  splashScreen: 'Splash Screen',
  homeScreen: 'Home Screen',
  productScreen: 'Product Screen',
  cardScreen: 'Card Screen',
}

export function Branding() {
  const [activeTab, setActiveTab] = useState(brandsTab.appLogo)
  const [imageUrl, setImageUrl] = useState({
    icon: '',
    splashScreen: '',
    storeLogo: '',
  })
  const {
    control,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      icon: null,
      name: '',
      primaryColor: '',
      secondaryColor: '',
      splashScreen: null,
      storeLogo: null,
    },
    criteriaMode: 'firstError',
    mode: 'onBlur',
  })

  console.log(imageUrl)
  const { tFeatures } = useFeaturesTranslation('branding')

  const styles = getStyles(defaultStyle)

  const submitForm = (data: FormData) => {
    console.log(data)
  }

  const handleClick = (tab: string) => {
    setActiveTab(tab)
  }

  const getBrandUI = () => {
    switch (activeTab) {
      case brandsTab.appLogo:
        return (
          <Box
            component="img"
            alt="icon-image"
            src={IconScreen}
            width="20%"
            minWidth="230px"
            maxWidth="250px"
          />
        )
      case brandsTab.splashScreen:
        return (
          <Box
            component="img"
            alt="splash-screen"
            src={SplashScreen}
            width="20%"
            minWidth="230px"
            maxWidth="250px"
          />
        )

      default:
        return <div>this is default</div>
    }
  }

  // function to generate local url from File|Blob types
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getImageUrl = (
    imageName: keyof Omit<FormData, 'name' | 'primaryColor' | 'secondaryColor'>
  ) => {
    const image = getValues(imageName)
    if (image) {
      const reader = new FileReader()
      reader.onload = () => {
        setImageUrl((prev) => ({
          ...prev,
          [imageName]: reader.result as string,
        }))
      }
      reader.readAsDataURL(image)
    }
  }

  return (
    <DashboardLayoutWrapper
      title={tFeatures('header.title')}
      headerChildren={
        <Box {...styles('formButtons')}>
          <AppButton variant="outlined" size="small" onClick={() => reset()}>
            {tFeatures('header.clearBtn')}
          </AppButton>
          <AppButton variant="contained" type="submit" form="brandingForm">
            {tFeatures('header.saveBtn')}
          </AppButton>
        </Box>
      }
    >
      <Box {...styles('brandingWrapper')}>
        <Box {...styles('sideBar')}>
          <Box
            component="form"
            id="brandingForm"
            onSubmit={handleSubmit(submitForm)}
          >
            <TextFormField
              name="name"
              control={control}
              label="App Name"
              errors={errors.name}
              placeholder="Enter the app name"
            />
            <FileUploadFormField
              control={control}
              name="icon"
              label="App Icon"
              allowedFormat=".png,.jpg,.jpeg,.webp,.svg"
            />
            <FileUploadFormField
              control={control}
              name="splashScreen"
              label="Splash Screen"
              allowedFormat=".png"
            />
            <FileUploadFormField
              control={control}
              name="storeLogo"
              label="Store Logo"
              allowedFormat=".png"
            />
            <ColorPickerFormField
              control={control}
              name="primaryColor"
              label="Primary Color"
            />
            <ColorPickerFormField
              control={control}
              name="secondaryColor"
              label="Secondary Color"
            />
          </Box>
        </Box>
        <Box {...styles('rightSection')}>
          <Box {...styles('tabSelectionWrapper')}>
            {Object.values(brandsTab).map((tabName) => (
              <Box
                key={tabName}
                {...styles(activeTab === tabName ? 'tabActive' : 'tabButton')}
                onClick={() => handleClick(tabName)}
              >
                {tabName}
              </Box>
            ))}
          </Box>
          <Box {...styles('tabContent')}> {getBrandUI()}</Box>
        </Box>
      </Box>
    </DashboardLayoutWrapper>
  )
}
