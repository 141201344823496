import { useSearchParams } from 'react-router-dom'

type ParamNames = Record<string, string>

export function useSearchParam<T extends ParamNames>(
  paramNames: T
): { [K in keyof T]: string | null } {
  const [searchParams] = useSearchParams()

  let queryParams = {} as { [K in keyof T]: string | null }

  Object.entries(paramNames).forEach(([key, value]) => {
    const paramValue = searchParams.get(value)
    queryParams = { ...queryParams, [key]: paramValue }
  })

  return queryParams
}
