import { useQuery } from '@tanstack/react-query'

import { ErrorResponse } from 'entities/definitions'
import { UserResponse } from 'entities/model'

import { userApi } from 'features/api'

export const UserInfoQueryKey = 'user-info-query'

export const useUserInfoQuery = () => {
  return useQuery<UserResponse, ErrorResponse>({
    queryKey: [UserInfoQueryKey],
    queryFn: async () => {
      const { data } = await userApi.getUser()
      return data
    },
    enabled: false,
  })
}
