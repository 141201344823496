import { StylesClasses, createStyles } from 'shared/utils'

const styles = createStyles({
  wrapper: {
    borderRadius: '5px',
  },
})

export type StylesClassNames = StylesClasses<typeof styles>

export default styles
