import { StylesClasses, createStyles } from 'shared/utils'

const styles = createStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: 'calc(100vh - 128px)',
  },
  leftWrapper: {
    flexBasis: '25%',
    backgroundColor: 'common.white',
    height: '100%',
    padding: '24px 16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    overflowY: 'auto',
  },
  rightWrapper: {
    flexBasis: '75%',
    height: '100%',
    padding: '24px',
    overflowY: 'auto',
    backgroundColor: 'custom.background.dark',
    display: 'flex',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
  },
})

export type StylesClassNames = StylesClasses<typeof styles>

export default styles
