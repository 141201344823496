import { useEffect } from 'react'

import { Box } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'

import { AppButton, Loader } from 'shared/components'
import { TextFormField } from 'shared/form'
import { getStyles } from 'shared/utils'
import {
  lowerCaseRegex,
  numberRegex,
  symbolRegex,
  upperCaseRegex,
} from 'shared/utils/model/regex.utils'

import { ResetPasswordPayload } from 'entities/model'

import { useFeaturesTranslation } from 'features/locale'
import {
  useResetPasswordMutation,
  useVerifyResetLinkMutation,
} from 'features/state/auth/auth.mutation'

import defaultStyles from './styles'

type FormData = ResetPasswordPayload & {
  confirmPassword?: string
}

const tokenKey = 'token'

export function ResetPasswordForm() {
  const { tFeatures } = useFeaturesTranslation('resetPassword')
  const { mutate, isPending } = useResetPasswordMutation()
  const { mutate: verifyResetLinkMutate, isSuccess } =
    useVerifyResetLinkMutation()
  const [searchParams] = useSearchParams()
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      password: '',
      token: '',
      confirmPassword: '',
    },
    criteriaMode: 'firstError',
    mode: 'onBlur',
  })

  const token = searchParams.get(tokenKey) || ''

  const styles = getStyles(defaultStyles)

  useEffect(() => {
    verifyResetLinkMutate({ token })
  }, [token, verifyResetLinkMutate])

  const onSubmit = (formData: FormData) => {
    const payload = { ...formData }
    delete payload.confirmPassword
    payload.token = token || ''
    mutate(payload)
  }

  return (
    <Box {...styles('container')}>
      {isSuccess ? (
        <Box width="100%">
          <Box {...styles('heading')}>{tFeatures('title')}</Box>
          <Box component="p" {...styles('caption')}>
            {tFeatures('subtitle')}
          </Box>
          <Box
            component="form"
            {...styles('form')}
            onSubmit={handleSubmit(onSubmit)}
          >
            <TextFormField
              name="password"
              control={control}
              errors={errors.password}
              label={tFeatures('labels.password')}
              type="password"
              placeholder={tFeatures('placeholders.password')}
              rules={{
                required: tFeatures('validations.password.required'),
                minLength: {
                  value: 8,
                  message: tFeatures('validations.password.length'),
                },
                validate: {
                  lowercase: (value = '') =>
                    lowerCaseRegex.test(value) ||
                    tFeatures('validations.password.lowercase'),
                  uppercase: (value = '') =>
                    upperCaseRegex.test(value) ||
                    tFeatures('validations.password.uppercase'),
                  number: (value = '') =>
                    numberRegex.test(value) ||
                    tFeatures('validations.password.number'),
                  symbol: (value = '') =>
                    symbolRegex.test(value) ||
                    tFeatures('validations.password.symbol'),
                },
              }}
            />
            <TextFormField
              name="confirmPassword"
              control={control}
              errors={errors.confirmPassword}
              label={tFeatures('labels.confirmPassword')}
              type="password"
              placeholder={tFeatures('labels.confirmPassword')}
              rules={{
                validate: (value) =>
                  value === getValues('password') ||
                  tFeatures('validations.passwordMatch'),
              }}
            />
            <AppButton
              loading={isPending}
              label={tFeatures('buttonText')}
              type="submit"
              fullWidth
              variant="contained"
            />
          </Box>
        </Box>
      ) : (
        <Loader loading />
      )}
    </Box>
  )
}
