import { storage } from 'shared/utils'

export const ACCESS_TOKEN = 'access_token'
export const REFRESH_TOKEN = 'refresh_token'

// FIXME: Add as per your API endpoint
export const loginEndpoint = 'api/v1/auth/login'
export const refreshTokenEndpoint = 'api/v1/auth/refresh-token'
export const signUpEndpoint = 'api/v1/user/update-details'

type UserSessionActive = {
  accessToken: string
  refreshToken: string
}

export const userSessionActive = (value: UserSessionActive) => {
  storage.setData(ACCESS_TOKEN, value.accessToken)
  storage.setData(REFRESH_TOKEN, value.refreshToken)
}

export const userSessionInactive = () => {
  localStorage.clear()
}
