import { StylesClasses, createStyles } from 'shared/utils'

const styles = createStyles({
  controlLabel: {
    '&.MuiTypography-root': {
      fontSize: '13px',
      fontWeight: '500',
    },
    '&.MuiFormControlLabel-root': {
      marginLeft: 0,
    },
  },

  radioGroup: {
    gap: 20,
  },
  // custom radio css

  radioInput: {
    '&.MuiRadio-root': {
      position: 'absolute',
      zIndex: 2,
      opacity: 0,
      top: 0,
      bottom: 0,
    },
    '&.MuiRadio-root.Mui-checked': {
      '+ .MuiTypography-root .gradient-wrapper': {
        background: 'linear-gradient(135deg, #43CBFF 0%, #9708CC 100%)',
        border: 'none',
      },
    },
  },
  gradientWrapper: {
    width: 18,
    position: 'relative',
    aspectRatio: '1/1',
    background: 'transparent',
    borderRadius: '50%',
    border: '1px solid',
    borderColor: 'custom.text.light',
    boxSizing: 'border-box',
  },
  innerCircle: {
    position: 'absolute',
    width: '55%',
    aspectRatio: '1/1',
    backgroundColor: 'common.white',
    borderRadius: '50%',
    inset: '0',
    margin: 'auto',
  },
  labelWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
  },
})

export type StylesClassNames = StylesClasses<typeof styles>

export default styles
