import {
  Controller,
  ControllerProps,
  ControllerRenderProps,
  FieldError,
  FieldValues,
} from 'react-hook-form'

import { TextField, TextFieldProps } from 'shared/components'

type Props<TFormValues extends FieldValues> = { errors?: FieldError } & Omit<
  TextFieldProps,
  keyof ControllerRenderProps
> &
  Omit<ControllerProps<TFormValues>, 'render'>

export function TextFormField<TFormValues extends FieldValues>({
  name,
  control,
  defaultValue,
  rules,
  shouldUnregister,
  errors,
  ...restTextFieldProps
}: Props<TFormValues>) {
  return (
    <Controller
      render={({ field }) => {
        return (
          <TextField
            {...restTextFieldProps}
            {...field}
            error={!!errors}
            helperText={errors?.message}
          />
        )
      }}
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      shouldUnregister={shouldUnregister}
    />
  )
}
