import { Box } from '@mui/material'

import { getStyles } from 'shared/utils'

import { ShopifyLogo } from 'entities/assets'
import { useEntitiesTranslation } from 'entities/locale'

import { loginWithShopifyUrl } from 'features/config'

import defaultStyles from './styles'

export function ShopifyLogin() {
  const { tEntities } = useEntitiesTranslation()

  const handleLogin = () => {
    window.location.href = loginWithShopifyUrl
  }

  const styles = getStyles(defaultStyles)

  return (
    <Box
      {...styles('button')}
      component="button"
      className="center"
      bgcolor="common.white"
      onClick={handleLogin}
    >
      <Box component="img" src={ShopifyLogo} />
      <Box component="span">{tEntities('loginWithShopify')}</Box>
    </Box>
  )
}
