import { ReactNode } from 'react'

import { Box } from '@mui/material'

import { CustomStyles, getStyles } from 'shared/utils'

import {
  AppButton,
  AppButtonProps,
  ButtonStyle,
  ComponentType,
} from '../AppButton'

// eslint-disable-next-line import/order
import defaultStyles, { StylesClassNames } from './styles'

export type DashboardHeaderProps<TActionValue extends string> = {
  title?: string
  customStyles?: CustomStyles<StylesClassNames>
  headerChildren?: ReactNode
  actionsConfig?: (AppButtonProps<ComponentType, ButtonStyle> & {
    value: TActionValue
  })[]
  onClick?: (value: TActionValue) => void
}

export function DashboardHeader<TActionValue extends string>({
  title,
  customStyles,
  headerChildren,
  actionsConfig = [],
  onClick,
}: DashboardHeaderProps<TActionValue>) {
  const styles = getStyles(defaultStyles, customStyles)

  return (
    <Box {...styles('wrapper')}>
      <Box {...styles('title')}>{title}</Box>
      <Box {...styles(['childrenWrapper'])}>
        <Box {...styles('children')}>{headerChildren}</Box>
        <Box {...styles('rightActions')}>
          {actionsConfig.map((config) => (
            <AppButton
              {...config}
              component="button"
              buttonStyle="basic"
              onClick={() => onClick && onClick(config.value)}
              {...styles('btn')}
            />
          ))}
        </Box>
      </Box>
    </Box>
  )
}
