import { StylesClasses, createStyles } from 'shared/utils'

const styles = createStyles({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
    marginTop: '50px',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  heading: {
    fontWeight: '500',
    fontSize: '30px',
  },
  caption: {
    fontWeight: 'light',
    marginBlock: '0',
    color: 'text.secondary',
  },
})

export type StylesClassNames = StylesClasses<typeof styles>

export default styles
