import { useEffect, useState } from 'react'

import { Box } from '@mui/material'

import { Loader } from 'shared/components'
import { getStyles } from 'shared/utils'

import { PLAN_DURATIONS, PlansDuration, PlansResponse } from 'entities/model'

import { Discount20 } from 'features/assets'
import { PricingCard } from 'features/components/PricingCard'
import { useFeaturesTranslation } from 'features/locale'
import { usePlansQuery } from 'features/state'

import defaultStyles from './styles'

export function PricingPlanModal() {
  const [activeTab, setActiveTab] = useState<PlansDuration>(
    PLAN_DURATIONS.monthly
  )
  const [plans, setPlans] = useState<PlansResponse['data']>([])

  const { refetch, isFetching } = usePlansQuery(activeTab)

  const { tFeatures } = useFeaturesTranslation('plans')

  useEffect(() => {
    const refetchPlans = async () => {
      const { data } = await refetch()
      setPlans(data?.data ?? [])
    }
    void refetchPlans()
  }, [activeTab, refetch])

  const handleClick = (plan: PlansDuration) => {
    setActiveTab(plan)
  }
  const styles = getStyles(defaultStyles)

  return (
    <Box {...styles('wrapper')}>
      <Box {...styles('pricingTag')}>Pricing</Box>
      <Box {...styles('heading')}>
        {tFeatures('title.first')}&nbsp;{' '}
        <Box component="span" {...styles('gradientText')}>
          {tFeatures('title.second')}
        </Box>
        {tFeatures('title.third')}
        <br />
        <Box component="span">
          {tFeatures('title.fourth')}&nbsp;
          <Box component="span" {...styles('gradientText')}>
            {tFeatures('title.fifth')}
          </Box>
        </Box>
        {tFeatures('title.sixth')}
      </Box>
      <Box {...styles('tabSelectionWrapper')}>
        <Box
          {...styles(
            activeTab === PLAN_DURATIONS.monthly ? 'tabActive' : 'tabButton'
          )}
          onClick={() => handleClick(PLAN_DURATIONS.monthly)}
        >
          {tFeatures('durations.monthly')}
        </Box>
        <Box
          {...styles(
            activeTab === PLAN_DURATIONS.yearly ? 'tabActive' : 'tabButton'
          )}
          onClick={() => handleClick(PLAN_DURATIONS.yearly)}
        >
          {tFeatures('durations.yearly')}
        </Box>
        <Box
          component="img"
          src={Discount20}
          alt="discount"
          {...styles('discount')}
        />
      </Box>
      <Loader loading={isFetching}>
        <Box {...styles('plans')}>
          {plans.map((data, index) => {
            const isPlatinum = plans.length - 1 === index
            return (
              <PricingCard
                key={data.plan.id}
                data={data}
                isPlatinum={isPlatinum}
              />
            )
          })}
        </Box>
      </Loader>
    </Box>
  )
}
