import { ReactNode } from 'react'

import { Box } from '@mui/material'

import {
  DashboardHeader,
  DashboardHeaderProps,
} from 'shared/components/DashboardHeader'
import { CustomStyles, getStyles } from 'shared/utils'

import defaultStyles, { StylesClassNames } from './styles'

type DashboardLayoutWrapperProps<TActionValue extends string> = {
  children: ReactNode
  customStyles?: CustomStyles<StylesClassNames>
} & DashboardHeaderProps<TActionValue>

export function DashboardLayoutWrapper<TActionValue extends string>({
  children,
  customStyles,
  ...rest
}: DashboardLayoutWrapperProps<TActionValue>) {
  const styles = getStyles(defaultStyles, customStyles)

  return (
    <Box {...styles('wrapper')}>
      <DashboardHeader {...rest} customStyles={customStyles} />
      <Box>{children}</Box>
    </Box>
  )
}
