import { StylesClasses, createStyles } from 'shared/utils'

const styles = createStyles({
  wrapper: {
    borderRadius: '8px',
    maxWidth: '400px',
    minHeight: '90px',
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
  },
  cardActionArea: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  cardMedia: {
    width: '67px',
    height: '67px',
    borderRadius: '4px',
  },
  title: {
    fontSize: '15px',
    fontWeight: '500',
  },
  description: {
    fontSize: '12px',
    color: 'custom.text.dark',
    fontWeight: '600',
  },
})

export type StylesClassNames = StylesClasses<typeof styles>

export default styles
