import { StylesClasses, createStyles } from 'shared/utils'

const styles = createStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'common.white',
    borderRadius: '16px',
    padding: '25px 20px',
    width: '320px',
  },
  platinum: {
    background: 'linear-gradient(180deg, #6A2C89 0%, #30143E 100%)',
    color: 'common.white',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'common.white',
    borderRadius: '16px',
    padding: '25px 20px',
    width: '320px',
    position: 'relative',
  },
  button: {
    backgroundColor: 'common.white',
    marginTop: 'auto',
    '&:hover': {
      backgroundColor: 'common.white',
    },
  },
  title: {
    fontSize: '22px',
    fontWeight: '600',
    marginBottom: '10px',
  },
  description: {
    fontSize: '14px',
    color: 'custom.text.light',
    marginBlock: '12px',
  },
  priceSection: {
    display: 'flex',
    gap: '20px',
    alignItems: 'center',
    marginTop: '22px',
  },
  currentPrice: {
    fontSize: '28px',
    fontWeight: '600',
  },
  durationIndicator: {
    fontSize: '14px',
    color: 'custom.text.light',
  },
  comparedPrice: {
    fontSize: '22px',
    fontWeight: '600',
    color: 'custom.text.light',
  },
  featuresSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    borderTop: '1px solid',
    borderBottom: '1px solid',
    borderColor: 'custom.border.main',
    paddingBlock: '10px',
    marginBlock: '18px',
  },
  featuresItem: {
    display: 'flex',
    gap: '5px',
    fontSize: '14px',
  },
  mostPopular: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    backgroundColor: 'common.white',
    borderRadius: '25px',
    padding: '3px 12px',
    fontSize: '12px',
    color: 'text.primary',
    fontWeight: '500',
  },
})

export type StylesClassNames = StylesClasses<typeof styles>

export default styles
