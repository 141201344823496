import { gql } from 'graphql-request'

export const productTypeQuery = gql`
  query ProductType {
    productTypes(first: 250) {
      edges {
        node
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`
export const productsQuery = gql`
  query Products @inContext(language: EN) {
    products(
      first: 10
      query: "(title:*) AND (productTags:*) AND (ProductType:*)"
      reverse: false
      sortKey: TITLE
    ) {
      nodes {
        handle
        onlineStoreUrl
        featuredImage {
          transformedSrc(maxWidth: 10, scale: 2)
        }
        title
      }
    }
  }
`

export const productTags = gql`
  query ProductTags {
    productTags(first: 250) {
      pageInfo {
        hasNextPage
      }
      edges {
        node
      }
    }
  }
`

export const collections = gql`
  query Collections {
    collections(first: 250, query: "(title:hy*)", sortKey: TITLE) {
      pageInfo {
        endCursor
        hasNextPage
      }
      nodes {
        title
        onlineStoreUrl
        image {
          transformedSrc(maxWidth: 100, scale: 3)
        }
      }
    }
  }
`

export type ProductQueryVariables = Partial<{
  language: 'EN'
  title: string
  productTags: string
  productType: string
  reverse: boolean
  sortKey: string
  first: number
  imageMaxWidth: number
  scale: number
}>

export const defaultProductQueryVariables: ProductQueryVariables = {
  language: 'EN',
  first: 10,
  title: '*',
  productTags: '*',
  productType: '*',
  reverse: false,
  sortKey: 'TITLE',
  imageMaxWidth: 60,
  scale: 2,
}

export const getProductQuery = (variables: ProductQueryVariables) => {
  const {
    language = 'EN',
    first = 10,
    title = '*',
    productTags = '*',
    productType = '*',
    reverse = false,
    sortKey = 'TITLE',
    imageMaxWidth = 60,
    scale = 2,
  } = variables

  return gql`
    query Products @inContext(language: ${language}) {
      products(
        first: ${first}
        query: "(title:${title}) AND (productTags:${productTags}) AND (ProductType:${productType})"
        reverse: ${reverse}
        sortKey: ${sortKey}
      ) {
        nodes {
          handle
          onlineStoreUrl
          featuredImage {
            transformedSrc(maxWidth: ${imageMaxWidth}, scale: ${scale})
          }
          title
        }
      }
    }
  `
}
