import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

import { useSnackbar } from 'shared/components'
import { storage } from 'shared/utils'

import { paths } from 'entities/config'
import { ErrorResponse } from 'entities/definitions'
import {
  ForgotPasswordPayload,
  ForgotPasswordResponse,
  ResetPasswordPayload,
  ResetPasswordResponse,
  SignInPayload,
  SignInResponse,
  ValidateResetTokenPayload,
  ValidateResetTokenResponse,
} from 'entities/model'
import {
  UserData,
  UserKey,
  UserSessionKey,
} from 'entities/state/user/user.session'

import { authApi } from 'features/api'

export const SignUpQuery = 'sign-up'
export const SignInQuery = 'sign-in'

export const useSignInMutation = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const mutation = useMutation<SignInResponse, ErrorResponse, SignInPayload>({
    mutationKey: [SignInQuery],
    mutationFn: async (formData) => {
      const { data } = await authApi.signIn(formData)
      return data
    },
    onSuccess: (res) => {
      queryClient.setQueryData<UserData>(UserSessionKey, res.data)
      storage.setData(UserKey, res.data)
      navigate(paths.dashboard)
    },
  })

  return mutation
}

export const useForgotPasswordMutation = () => {
  const { showSnackbar } = useSnackbar()

  const mutation = useMutation<
    ForgotPasswordResponse,
    ErrorResponse,
    ForgotPasswordPayload
  >({
    mutationFn: async (formData) => {
      const { data } = await authApi.sendForgotPasswordEmail(formData)
      return data
    },
    onSuccess: (res) => {
      showSnackbar({ message: res.message, type: 'success' })
    },
  })

  return mutation
}

export const useVerifyResetLinkMutation = () => {
  const { showSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const mutation = useMutation<
    ValidateResetTokenResponse,
    ErrorResponse,
    ValidateResetTokenPayload
  >({
    mutationFn: async (formData) => {
      const { data } = await authApi.validateResetToken(formData)
      return data
    },
    onError: (error) => {
      if (error.response?.data.statusCode === 401) {
        showSnackbar({
          message: 'Reset link has expired. Please request a new one',
          type: 'error',
        })
        navigate(paths.forgotPassword)
      }
    },
  })

  return mutation
}
export const useResetPasswordMutation = () => {
  const navigate = useNavigate()
  const { showSnackbar } = useSnackbar()

  const mutation = useMutation<
    ResetPasswordResponse,
    ErrorResponse,
    ResetPasswordPayload
  >({
    mutationFn: async (formData) => {
      const { data } = await authApi.resetPassword(formData)
      return data
    },
    onSuccess: (res) => {
      showSnackbar({ message: res.message, type: 'success' })
      navigate(paths.signIn)
    },
  })

  return mutation
}
