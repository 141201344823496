export const defaultEn = {
  navLinkTitles: {
    screens: 'Screens',
    themes: 'Themes',
    branding: 'Branding',
    notifications: 'Notifications',
    plugins: 'Plugins',
    deeplink: 'Deeplink',
    account: 'Account',
    settings: 'Settings',
  },
  commonHeader: {
    greeting: 'Welcome',
    launchLabel: 'Launch',
  },
  loginWithShopify: 'Login With Shopify',
  paymentFields: {
    installment: {
      label: 'No. of Installments',
      placeholder: 'Enter No. of Installments',
    },
    minAmount: {
      label: 'Min. amount for pay later availability',
      placeholder: 'Min. Amount',
    },

    payLater: {
      label: 'Title for Pay later',
      placeholder: '{amount} x 4 interest- free payments',
    },
  },
  pricingCard: {
    buttonText: 'Choose Plan',
    mostPopular: 'Most Popular🔥',
  },
}
