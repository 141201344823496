import { Suspense } from 'react'

import { Loader, SnackbarProvider } from 'shared/components'

import {
  I18nProvider,
  QueryProvider,
  RouterProvider,
  ThemeProvider,
} from './providers'

import './App.css'

function App() {
  return (
    <I18nProvider>
      <SnackbarProvider>
        <QueryProvider>
          <ThemeProvider>
            <Suspense fallback={<Loader fullView loading />}>
              <RouterProvider />
            </Suspense>
          </ThemeProvider>
        </QueryProvider>
      </SnackbarProvider>
    </I18nProvider>
  )
}

export default App
